import React from 'react';

const MiniplayerButton = ({ width = 28, height = 28, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 28 28" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M15.5167 18.6663H18.3167C20.65 18.6663 21.5833 17.733 21.5833 15.3997V12.5997C21.5833 10.2663 20.65 9.33301 18.3167 9.33301H15.5167C13.1833 9.33301 12.25 10.2663 12.25 12.5997V15.3997C12.25 17.733 13.1833 18.6663 15.5167 18.6663Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.8333 23.3337H8.16667C3.5 23.3337 2.33334 22.167 2.33334 17.5003V10.5003C2.33334 5.83366 3.5 4.66699 8.16667 4.66699H19.8333C24.5 4.66699 25.6667 5.83366 25.6667 10.5003V17.5003C25.6667 22.167 24.5 23.3337 19.8333 23.3337Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MiniplayerButton;
