import React from 'react'

const SubscriptionsIcon = (
    {
        color = "#292D32",
        size = 24,
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 11.75C14.4853 11.75 16.5 9.73528 16.5 7.25C16.5 4.76472 14.4853 2.75 12 2.75C9.51472 2.75 7.5 4.76472 7.5 7.25C7.5 9.73528 9.51472 11.75 12 11.75Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path opacity="0.4" d="M15.84 22.2507H5.25V18.7482C5.25 17.4891 5.75018 16.2815 6.64051 15.3912C7.53084 14.5009 8.73838 14.0007 9.9975 14.0007H13.995C14.1624 13.992 14.3301 13.992 14.4975 14.0007M20.76 16.0482C20.4544 15.7417 20.0522 15.5503 19.6216 15.5065C19.191 15.4628 18.7585 15.5694 18.3975 15.8082C18.0388 15.5342 17.5932 15.3988 17.1427 15.4269C16.6921 15.4549 16.2668 15.6446 15.9449 15.9611C15.623 16.2775 15.426 16.6995 15.3902 17.1495C15.3544 17.5995 15.4822 18.0473 15.75 18.4107L18.405 21.0657L21 18.4332C21.2447 18.0702 21.3547 17.633 21.3109 17.1974C21.2671 16.7617 21.0722 16.3552 20.76 16.0482Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    )
}

export default SubscriptionsIcon
