import React from "react";
import { ModalVerifyEmail } from "../newComponents/molecules";
import { openModal } from "../store/slices/common/modal";

export const isGuestUser = (dispatch) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user-details"));

  if (!loggedInUser?.isIdentityVerified) {
    dispatch(
      openModal({
        title: "Verify Email",
        content: (
          <ModalVerifyEmail
            email={loggedInUser?.email}
            name={loggedInUser?.name}
          />
        ),
        hasCloseBtn: true,
      })
    );
    return true;
  }
  return false;
};
