import React from "react";

const VideoIcon = ({ white = false }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4415 17.5166H5.17484C2.5415 17.5166 1.6665 15.7666 1.6665 14.0083V6.99161C1.6665 4.35828 2.5415 3.48328 5.17484 3.48328H10.4415C13.0748 3.48328 13.9498 4.35828 13.9498 6.99161V14.0083C13.9498 16.6416 13.0665 17.5166 10.4415 17.5166Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2669 14.75L13.9502 13.125V7.86665L16.2669 6.24165C17.4002 5.44999 18.3335 5.93332 18.3335 7.32499V13.675C18.3335 15.0667 17.4002 15.55 16.2669 14.75Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5835 9.66663C10.2739 9.66663 10.8335 9.10698 10.8335 8.41663C10.8335 7.72627 10.2739 7.16663 9.5835 7.16663C8.89314 7.16663 8.3335 7.72627 8.3335 8.41663C8.3335 9.10698 8.89314 9.66663 9.5835 9.66663Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
