import React from "react";
import { ProfileHeader } from "./ProfileHeader";

const StoryModal = ({
  username,
  date,
  profileImage,
  ProgressBarComponent,
  children,
  handleCancel,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="w-full max-w-md bg-white rounded-2xl overflow-hidden shadow-lg relative">
        <div className="flex flex-col h-full mx-8 my-4">
          <div className="p-2">
            <ProfileHeader
              username={username}
              date={date}
              image={profileImage}
              onCancel={handleCancel}
            />
            {ProgressBarComponent}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default StoryModal;
