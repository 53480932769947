// list of colors from variable.scss:
// primary
// darkblue
// secondary
// blue
// black
// lightbluebg
// white
// bordercolor
// gray500
// darkblack
// red
// lightgraybg
// lightgray400
// green
// lightcoffee
// black200
// lightgray300
// lightgay700
// darkbg
// darkSecondary
// darkborder

export const getColor = (color) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(`--${color}`)
    ?.trim();
};
