import React from "react";
import { Menu, MenuItem } from "@mui/material";

const MediaMenuOptions = ({
  anchorEl,
  handleMenuClose,
  openImageUploadDialog,
  openCamera,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          openImageUploadDialog();
        }}
      >
        Choose from your device
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          openCamera();
        }}
      >
        Take a photo
      </MenuItem>
    </Menu>
  );
};

export default MediaMenuOptions;
