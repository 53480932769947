import { Box, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthenticationIcon from "./../../../../../../assets/images/authenticator-icon.png";
import VerificationSmsIcon from "./../../../../../../assets/images/verification-sms.svg";
import { useDispatch, useSelector } from "react-redux";
import { Activate2Step } from "../../../../../../store/middlewares/web/profile/myProfile/account";
import { resetqrImage } from "../../../../../../store/slices/web/profile/myProfile/twoStepAuth.slice";
import { QrSrc } from "./../../../../../molecules";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { toggleTwoFactor } from "../../../../../../store/services/web/profile/phoneNumber.service";
import { openToast } from "../../../../../../store/slices/common/toast";

const ModalTwoStepAuth = () => {
  const { qrImage } = useSelector((state) => state.qr);
  const { me } = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  const [qrSrc, setQrSrc] = useState("");

  const handleClose = () => {
    // close modal
    dispatch(resetqrImage());
    setQrSrc("");
    dispatch(closeModal());
  };

  const handleChange = async (e) => {
    // check/uncheck handle
    let tempArray = [];
    checked?.forEach((item) => {
      tempArray.push(item);
    });
    if (tempArray.includes(e.target.value)) {
      tempArray = tempArray.filter((arr) => arr !== e.target.value);
    } else {
      tempArray.push(e.target.value);
    }
    setChecked(tempArray);
    // when authenticator is turned on
    if (e.target.value === "authenticator") {
      dispatch(Activate2Step());
    }
    
    if (e.target.value === "verification-via-sms") {
      try {
        await toggleTwoFactor();
        dispatch(openToast({ 
          type: "success", 
          message: tempArray.includes("verification-via-sms") 
            ? "SMS verification enabled" 
            : "SMS verification disabled"
        }));
      } catch (error) {
        setChecked(checked);
        dispatch(openToast({ 
          type: "error", 
          message: error.response?.data?.message || "Failed to update SMS verification"
        }));
      }
    }
  };

  useEffect(() => {
    let tempArray = [];
    if (me?.twoStepAuthApp) {
      tempArray.push("authenticator");
    }
    if (me?.isSmsTwoFactorEnabled) {
      tempArray.push("verification-via-sms");
    }
    setChecked(tempArray);
  }, [me]);

  useEffect(() => {
    // if qr is generated
    if (qrImage) {
      setQrSrc(qrImage);
    } else {
      setQrSrc("");
    }
  }, [qrImage]);

  return (
    <>
      {qrSrc ? (
        <QrSrc qrSrc={qrSrc} handleClose={handleClose} />
      ) : (
        <Box className="gray-border border-radius-10 p-3">
          <p className="font-20 font-medium text-darkblue mb-3">
            Primary Options
          </p>
          <Box className="lightgray-400 p-3 flex justify-between border-radius-10 mb-3  gap-2">
            <Box className="flex items-start gap-4 w-full">
              <img src={AuthenticationIcon} alt="AuthenticationIcon" />
              <Box>
                <p className="font-18 font-medium text-darkblue ">
                  Authenticator App
                </p>
                <p>
                  You can use Microsoft or Google Authenticator application for
                  iOS or Android
                </p>
              </Box>
            </Box>
            <FormControlLabel
              className="black-border"
              control={
                <Switch
                  checked={checked?.includes("authenticator")}
                  onChange={(e) => handleChange(e)}
                  name="checkedA"
                  value={"authenticator"}
                  color="primary"
                />
              }
            />
          </Box>

          <Box className="lightgray-400 p-3 flex justify-between border-radius-10  gap-2">
            <Box className="flex items-start gap-4 w-full">
              <img src={VerificationSmsIcon} alt="VerificationSmsIcon" />
              <Box>
                <p className="font-18 font-medium text-darkblue ">
                  Verification Via SMS
                </p>
              </Box>
            </Box>
            <FormControlLabel
              className="black-border"
              control={
                <Switch
                  checked={checked?.includes("verification-via-sms")}
                  onChange={(e) => handleChange(e)}
                  name="checkedA"
                  value={"verification-via-sms"}
                  color="primary"
                />
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ModalTwoStepAuth;
