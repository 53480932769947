import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/user";

export const NotificationUser = async (payload) => {
  const URL = `${URI}/getUserNotification`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during notification", error);
    throw error;
  }
};

export const GetUser = async (payload) => {
  const URL = `${URI}/getUser`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during notification", error);
    throw error;
  }
};

export const BanUser = async (payload) => {
  const URL = `${URI}/banUser`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Ban User", error);
    throw error;
  }
};

export const DeleteUser = async (payload) => {
  const URL = `${URI}/deleteUser`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Delete User", error);
    throw error;
  }
};
