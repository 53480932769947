import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Layout from "../component/admin/Layout";
import RestrictedUsers from "../pages/admin/RestrictedUsers";
import { useSelector } from "react-redux";

//Pages
const PostDetail = lazy(() => import("../pages/admin/PostDetail"));
const ReportedUsers = lazy(() => import("../pages/admin/ReportedUsers"));
const BlockedUsers = lazy(() => import("../pages/admin/BlockedUsers"));
const Subscriptions = lazy(() => import("../pages/admin/Subscriptions"));
const ReportedPosts = lazy(() => import("../pages/admin/ReportedPosts"));
const AdminReportReasons = lazy(() =>
  import("../pages/admin/CMS/ReportReasons")
);
const AdminDashbord = lazy(() => import("../pages/admin/Dashboard"));
const AdminUsers = lazy(() => import("../pages/admin/Users"));
const AdminDeleteUsers = lazy(() => import("../pages/admin/Users/DeleteUsers"));
const AdminCreators = lazy(() =>
  import("../pages/admin/Creators/ActiveCreators")
);
const AdminPendingCreators = lazy(() =>
  import("../pages/admin/Creators/PendingCreators")
);
const AdminDeleteCreators = lazy(() =>
  import("../pages/admin/Creators/DeleteCreators")
);
const AdminUser = lazy(() => import("../pages/admin/User"));
const AdminCMS = lazy(() => import("../pages/admin/CMS"));
const AdminSettings = lazy(() => import("../pages/admin/Settings"));
const AdminGeneralSettings = lazy(() =>
  import("../pages/admin/Settings/General")
);
const AdminCountryCode = lazy(() => import("../pages/admin/CountryCode"));
const AdminChangePassword = lazy(() => import("../pages/admin/ChangePassword"));
const AdminLanguages = lazy(() => import("../pages/admin/Languages"));
const AdminPosts = lazy(() => import("../pages/admin/Posts"));
const AdminDeletedPosts = lazy(() => import("../pages/admin/DeletedPosts"));
const AdminDocumentTypes = lazy(() => import("../pages/admin/DocumentType"));
const AdminUserPosts = lazy(() => import("../pages/admin/User/Posts"));
const AdminStates = lazy(() => import("../pages/admin/States"));
const NotFound = lazy(() => import("../pages/web/common/NotFound"));
const Login = lazy(() => import("../pages/admin/Login"));

const basePath = "";

const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};

const adminRoutes = [
  {
    path: generatePath("dashboard"),
    element: <AdminDashbord />,
  },
  {
    path: generatePath("country-code"),
    element: <AdminCountryCode />,
  },
  {
    path: generatePath("change-password"),
    element: <AdminChangePassword />,
  },
  {
    path: generatePath("active-users"),
    element: <AdminUsers />,
  },
  {
    path: generatePath("delete-users"),
    element: <AdminDeleteUsers />,
  },
  {
    path: generatePath("users/:id"),
    element: <AdminUser />,
  },
  {
    path: generatePath("users/:id/posts"),
    element: <AdminUserPosts />,
  },
  {
    path: generatePath("creators/:id/posts"),
    element: <AdminUserPosts />,
  },
  {
    path: generatePath("active-creators"),
    element: <AdminCreators />,
  },
  {
    path: generatePath("delete-creators"),
    element: <AdminDeleteCreators />,
  },
  {
    path: generatePath("posts/:id"),
    element: <PostDetail />,
  },
  {
    path: generatePath("cms/:types"),
    element: <AdminCMS />,
  },
  {
    path: generatePath("languages"),
    element: <AdminLanguages />,
  },
  {
    path: generatePath("settings/post"),
    element: <AdminSettings />,
  },
  {
    path: generatePath("settings/general"),
    element: <AdminGeneralSettings />,
  },
  {
    path: generatePath("report-reasons/:type"),
    element: <AdminReportReasons />,
  },
  {
    path: generatePath("reported/users"),
    element: <ReportedUsers />,
  },
  {
    path: generatePath("reported/posts"),
    element: <ReportedPosts />,
  },
  {
    path: generatePath("active-posts"),
    element: <AdminPosts />,
  },
  {
    path: generatePath("delete-posts"),
    element: <AdminDeletedPosts />,
  },
  {
    path: generatePath("blocked-users"),
    element: <BlockedUsers />,
  },
  {
    path: generatePath("subscriptions"),
    element: <Subscriptions />,
  },
  {
    path: generatePath("restricted-users"),
    element: <RestrictedUsers />,
  },
  {
    path: generatePath("pending-creators"),
    element: <AdminPendingCreators />,
  },
  {
    path: generatePath("states"),
    element: <AdminStates />,
  },
  {
    path: generatePath("document-types"),
    element: <AdminDocumentTypes />,
  },
];

const AdminNavigation = () => {
  useDocumentTitle("YouOnly-Admin");
  const { isTokenValid } = useSelector((state) => state.auth);

  const user = JSON.parse(localStorage.getItem("user-details-admin"));

  return (
    <Routes>
      {/* if user is logged in redirect to dashboard */}
      <Route
        path=""
        element={
          !user || !isTokenValid ? <Login /> : <Navigate to="dashboard" />
        }
      />
      {/* if user is not present show unauthorized ui */}
      <Route
        element={!user || !isTokenValid ? <Navigate to="/admin" /> : <Layout />}
      >
        {adminRoutes.map((route, index) => {
          return (
            <Route
              key={`${route.path}${index}`}
              path={route.path}
              element={route.element}
            />
          );
        })}
        {/* all undefined routes are handled here for admin */}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminNavigation;
