export const imageMimeTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
  "image/tiff",
];

export const audioMimeTypes = [
  "audio/mpeg",
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "audio/flac",
  "audio/aac",
  "audio/m4a",
  "audio/x-ms-wma",
  "audio/x-aiff",
  "audio/x-m4a",
  "audio/webm",
];

export const acceptedFileTypes = (mimeTypes) => mimeTypes
.map(mime => mime.split('/')[1])
.map(ext => `.${ext}`)
.join(',');