export const SOCIAL_MEDIA_URLS = {
  INSTAGRAM: {
    BASE: 'https://www.instagram.com/',
    PREFIX: '',
  },
  TWITTER: {
    BASE: 'https://x.com/',
    PREFIX: '',
  },
  YOUTUBE: {
    BASE: 'https://www.youtube.com/',
    PREFIX: '',
  },
  TIKTOK: {
    BASE: 'https://www.tiktok.com/',
    PREFIX: '@',
  },
}; 