import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.css';
import Controls from './Controls';
import MobileControls from './MobileControls';
import { formatDuration } from '../../../../../../utils/formatDuration';

const VideoPlayer = ({ src, onDurationChange, onPlay, onPause, onEnded }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [error, setError] = useState(null);
  const [isMiniplayer, setIsMiniplayer] = useState(false);
  const [miniplayerPosition, setMiniplayerPosition] = useState({ x: 20, y: 20 });
  const [isMobileView, setIsMobileView] = useState(false);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const dragRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateTime = () => setCurrentTime(video.currentTime);
    const updateDuration = () => {
      const newDuration = video.duration;
      setDuration(newDuration);
      if (onDurationChange) {
        onDurationChange(newDuration);
      }
    };
    const handleError = (e) => {
      console.error('Video error:', e);
      setError('Error loading video');
    };
    const handleVideoPlay = () => {
      setIsPlaying(true);
      if (onPlay) onPlay();
    };
    const handleVideoPause = () => {
      setIsPlaying(false);
      if (onPause) onPause();
    };
    const handleVideoEnded = () => {
      setIsPlaying(false);
      if (onEnded) onEnded();
    };

    video.addEventListener('timeupdate', updateTime);
    video.addEventListener('loadedmetadata', updateDuration);
    video.addEventListener('error', handleError);
    video.addEventListener('play', handleVideoPlay);
    video.addEventListener('pause', handleVideoPause);
    video.addEventListener('ended', handleVideoEnded);

    return () => {
      video.removeEventListener('timeupdate', updateTime);
      video.removeEventListener('loadedmetadata', updateDuration);
      video.removeEventListener('error', handleError);
      video.removeEventListener('play', handleVideoPlay);
      video.removeEventListener('pause', handleVideoPause);
      video.removeEventListener('ended', handleVideoEnded);
    };
  }, [onDurationChange, onPlay, onPause, onEnded]);

  useEffect(() => {
    if (!isMiniplayer) return;

    const handleDrag = (e) => {
      if (!dragRef.current) return;
      
      const dx = e.clientX - dragRef.current.startX;
      const dy = e.clientY - dragRef.current.startY;
      
      setMiniplayerPosition(prev => ({
        x: Math.max(0, Math.min(window.innerWidth - 320, prev.x + dx)),
        y: Math.max(0, Math.min(window.innerHeight - 180, prev.y + dy))
      }));
      
      dragRef.current.startX = e.clientX;
      dragRef.current.startY = e.clientY;
    };

    const handleDragEnd = () => {
      dragRef.current = null;
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };

    const handleDragStart = (e) => {
      dragRef.current = {
        startX: e.clientX,
        startY: e.clientY
      };
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    };

    const miniplayerElement = playerRef.current;
    if (miniplayerElement) {
      miniplayerElement.addEventListener('mousedown', handleDragStart);
    }

    return () => {
      if (miniplayerElement) {
        miniplayerElement.removeEventListener('mousedown', handleDragStart);
      }
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isMiniplayer]);

  useEffect(() => {
    const checkPlayerWidth = () => {
      if (playerRef.current) {
        const width = playerRef.current.offsetWidth;
        setIsMobileView(width < 300);
      }
    };

    const resizeObserver = new ResizeObserver(checkPlayerWidth);
    if (playerRef.current) {
      resizeObserver.observe(playerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handlePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
        setError('Error playing video');
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
    setIsMuted(newVolume === 0);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const handleTimelineChange = (newTime) => {
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleSkipForward = () => {
    const newTime = Math.min(videoRef.current.currentTime + 5, duration);
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleSkipBackward = () => {
    const newTime = Math.max(videoRef.current.currentTime - 5, 0);
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      playerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleMiniplayerToggle = () => {
    if (isFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
    setIsMiniplayer(!isMiniplayer);
  };

  return (
    <div 
      className={`${styles.videoPlayer} ${isMiniplayer ? styles.miniplayer : ''}`}
      ref={playerRef}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => isPlaying && setShowControls(false)}
      onMouseMove={() => setShowControls(true)}
      style={isMiniplayer ? { position: 'fixed', left: miniplayerPosition.x, top: miniplayerPosition.y } : {}}
    >
      <video
        ref={videoRef}
        className={styles.video}
        src={src}
        onClick={handlePlay}
        playsInline
        preload="metadata"
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {error && <div className={styles.error}>{error}</div>}
      {isMobileView ? (
        <MobileControls
          isPlaying={isPlaying}
          currentTime={currentTime}
          duration={duration}
          onPlay={handlePlay}
          onTimelineChange={handleTimelineChange}
          onFullscreenToggle={handleFullscreenToggle}
          formatTime={formatDuration}
          showControls={showControls}
        />
      ) : (
        <Controls
          isPlaying={isPlaying}
          volume={volume}
          isMuted={isMuted}
          currentTime={currentTime}
          duration={duration}
          isFullscreen={isFullscreen}
          isMiniplayer={isMiniplayer}
          showControls={showControls}
          onPlay={handlePlay}
          onVolumeChange={handleVolumeChange}
          onMuteToggle={handleMuteToggle}
          onTimelineChange={handleTimelineChange}
          onFullscreenToggle={handleFullscreenToggle}
          onMiniplayerToggle={handleMiniplayerToggle}
          onNext={handleSkipForward}
          onPrevious={handleSkipBackward}
          formatTime={formatDuration}
        />
      )}
    </div>
  );
};

export default VideoPlayer;