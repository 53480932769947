import axiosClient from "../../../../utils/api";

export const getSuggestions = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/suggestion/userSuggestion",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during get Suggestions Service:", error);
    throw error;
  }
};
