import React, { useRef } from 'react';
import styles from './styles.module.css';

const Timeline = ({ currentTime, duration, onTimelineChange, formatTime, isMobile }) => {
  const timelineRef = useRef(null);

  const handleTimelineClick = (e) => {
    if (!timelineRef.current) return;
    
    const rect = timelineRef.current.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    const newTime = Math.max(0, Math.min(position * duration, duration));
    onTimelineChange(newTime);
  };

  return (
    <div className={styles.timelineContainer}>
      {!isMobile && (
        <div className={styles.timeLabels}>
          <span className={styles.timeLabel}>{formatTime(currentTime)}</span>
          <span className={styles.timeLabel}>{formatTime(duration)}</span>
        </div>
      )}
      <div
        ref={timelineRef}
        className={styles.timeline}
        onClick={handleTimelineClick}
      >
        <div
          className={styles.timelineProgress}
          style={{ width: `${(currentTime / duration) * 100}%` }}
        >
          <div className={styles.timelineHandle} />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
