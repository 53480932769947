import React, { useRef } from 'react';
import styles from './styles.module.css';
import MuteButton from '../../../../../../assets/SVG/media/videoPlayer/MuteButton';
import UnmuteButton from '../../../../../../assets/SVG/media/videoPlayer/UnmuteButton';

const VolumeControl = ({ volume, isMuted, onVolumeChange, onMuteToggle, size }) => {
  const sliderRef = useRef(null);

  const handleVolumeClick = (e) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    onVolumeChange(Math.max(0, Math.min(1, position)));
  };

  return (
    <div className={styles.volumeControl}>
      <button 
        className={styles.muteButton}
        onClick={onMuteToggle}
      >
        {isMuted ? <UnmuteButton height={size} width={size}/> : <MuteButton height={size} width={size}/>}
      </button>
      
      <div 
        className={styles.volumeSlider}
        ref={sliderRef}
        onClick={handleVolumeClick}
      >
        <div 
          className={styles.volumeProgress}
          style={{ width: `${isMuted ? 0 : volume * 100}%` }}
        />
      </div>
    </div>
  );
};

export default VolumeControl;
