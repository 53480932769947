import axiosClient from "../../../../utils/api";
import { axiosAuthClient } from "../../../../utils/api";

export const login = async (payload) => {
  try {
    const { type, ...body } = payload;
    const response = await axiosAuthClient.request({
      url: `/auth/login${type ? `/${type}` : ""}`,
      method: "post",
      data: body,
    });
    let { data } = response;
    return { email: payload.email, ...data };
  } catch (err) {
    let tempObj = {
      message: err?.response?.data?.message
        ? err?.response?.data?.message
        : err?.message,
    };
    return tempObj;
  }
};

export const verifytotp = async (payload) => {
  try {
    const response = await axiosAuthClient.request({
      url: "/auth/verifytotp",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (err) {
    let tempObj = { message: err?.response?.data?.message };
    return tempObj;
  }
};

export const logout = async () => {
  try {
    const response = await axiosClient.request({
      url: "/profile/logout",
      method: "post",
    });
    return response.data;
  } catch (err) {
    let tempObj = { message: err?.response?.data?.message };
    return tempObj;
  }
};

export const verifyIdentity = async (token) => {
  try {
    const response = await axiosClient.request({
      url: "/auth/identityVerification",
      method: "post",
      data: { token },
    });
    return response.data;
  } catch (err) {
    let tempObj = {
      message: err?.response?.data?.message
        ? err?.response?.data?.message
        : err?.message,
    };
    return tempObj;
  }
};

export const resendIdentityVerificationEmail = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/auth/resendIdentityVerificationEmail",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (err) {
    let tempObj = { message: err?.response?.data?.message };
    return tempObj;
  }
};
