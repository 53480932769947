import axiosClient from "../../../../utils/api";

export const getSocialLinks = async () => {
  try {
    const response = await axiosClient.request({
      url: "/profile/getSocialLinks",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at SocialLinks Service", error);
    throw error;
  }
};

export const setSocialLinks = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/profile/setSocialLinks",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update SocialLinks Service", error);
    throw error;
  }
};
