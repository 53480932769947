import { Box, Button, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import LockIcon from "./../../../../../../assets/images/lock.svg";
import { useFormik } from "formik";
import {
  oldPassword,
  newPassword,
  confirmPassword,
} from "../../../../../../utils/validation/ChangePasswordForm";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import EyeIconslash from "../../../../../../assets/images/eye-slash.svg";
import EyeIcon from "../../../../../../assets/images/eye.svg";
import { Input } from "../../../../../atoms";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { changePasswordAction } from "../../../../../../store/middlewares/web/profile/myProfile/me";
import { resetChangePasswordError } from "../../../../../../store/slices/web/profile/myProfile/me.slice";

const ModalProfilePassword = () => {
  const [showField, setShowField] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const { changePasswordMessage, changePasswordError, changePasswordSuccess } =
    useSelector((state) => state.me);

  const handleVisiblityChange = (field) => {
    //toggle visiblity of inputs
    let tempObj = JSON.parse(JSON.stringify(showField));
    tempObj[`${field}`] = !showField[`${field}`];
    setShowField(tempObj);
  };

  const dispatch = useDispatch();

  const handleClose = () => {
    formik.resetForm();
    setShowField({
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
    formik.setErrors({});
    formik.setTouched({}, false);
    formik.resetForm();
    dispatch(closeModal());
  };

  const schema = Yup.object({
    //validation
    oldPassword: oldPassword,
    newPassword: newPassword,
    confirmPassword: confirmPassword,
  });

  const handleSubmit = (values) => {
    console.log(values);
    // eslint-disable-next-line no-unused-vars
    const { confirmPassword, ...passwordData } = values;
    dispatch(changePasswordAction(passwordData));
  };

  // intialize the form object
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const handleChange = (e) => {
    formik.handleChange(e);
    dispatch(resetChangePasswordError());
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-2">
        <Input
          name="oldPassword"
          value={formik.values.oldPassword}
          type={showField.oldPassword ? "type" : "password"}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.oldPassword && formik.touched.oldPassword}
          placeholder="Your Password"
          id="old-password"
          errorMsg={formik.errors.oldPassword}
          startAdornment={
            <InputAdornment position="start">
              <img src={LockIcon} alt="LockIcon" className="img-dark-filter" />{" "}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <img
                src={showField.oldPassword ? EyeIcon : EyeIconslash}
                alt="EyeIcon"
                onClick={() => handleVisiblityChange("oldPassword")}
                className="cursor-pointer img-dark-filter"
              />
            </InputAdornment>
          }
        />
        <Input
          name="newPassword"
          value={formik?.values?.newPassword}
          type={showField.newPassword ? "type" : "password"}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.newPassword && formik.touched.newPassword}
          placeholder="New Password"
          id="new-password"
          errorMsg={formik.errors.newPassword}
          startAdornment={
            <InputAdornment position="start">
              <img src={LockIcon} alt="LockIcon" className="img-dark-filter" />{" "}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <img
                src={showField.newPassword ? EyeIcon : EyeIconslash}
                alt="EyeIcon"
                onClick={() => handleVisiblityChange("newPassword")}
                className="cursor-pointer img-dark-filter"
              />
            </InputAdornment>
          }
        />
        <Input
          name="confirmPassword"
          value={formik.values.confirmPassword}
          type={showField.confirmPassword ? "text" : "password"}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.errors.confirmPassword && formik.touched.confirmPassword
          }
          placeholder="Confirm Password"
          id="confirm-password"
          errorMsg={formik.errors.confirmPassword}
          startAdornment={
            <InputAdornment position="start">
              <img src={LockIcon} alt="LockIcon" className="img-dark-filter" />{" "}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <img
                src={showField.confirmPassword ? EyeIcon : EyeIconslash}
                alt="EyeIcon"
                onClick={() => handleVisiblityChange("confirmPassword")}
                className="cursor-pointer img-dark-filter"
              />
            </InputAdornment>
          }
        />
        {!!(changePasswordError || changePasswordSuccess) && (
          <span
            className={`${
              changePasswordError ? "text-red-500" : "text-green-500"
            }`}
          >
            {changePasswordMessage}
          </span>
        )}
      </div>
      <Box className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="m-auto px-12">
          Save
        </Button>
      </Box>
    </form>
  );
};

export default ModalProfilePassword;
