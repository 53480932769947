/* eslint-disable no-undef */
/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */

import axios from "axios";
import { encryptAndStoreToken, getDecryptedToken } from "./CryptoJS";
import LocalStorageService from "./localstorage-services";

axios.defaults.withCredentials = true;
const youOnly = {
  name: "youonly",
  account: `${process.env.REACT_APP_LIVE_URL || "https://web-dev.youonly.io"}`,
  api: `${process.env.REACT_APP_API_URL || "https://api-dev.youonly.io"}`,
  live: `${process.env.REACT_APP_LIVE_URL || "https://web-dev.youonly.io"}`,
  admin: `${
    process.env.REACT_APP_ADMIN_URL || "https://web-dev.youonly.io/admin"
  }`,
};

const localhost = {
  account: "http://localhost:3000",
  admin: "http://localhost:3000/admin",
  api: "http://localhost:8080",
};

const path = window.location.href;
const live = !!path.match(youOnly.live);

const apiURL = live ? youOnly.api : localhost.api;
const accountURL = live ? youOnly.account : localhost.account;
const adminURL = live ? youOnly.admin : localhost.admin;

export const API_URL = apiURL;
export const ACCOUNT_URL = accountURL;
const defaultOptions = {
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    accept: "application/json",
  },
};

const axiosClient = axios.create(defaultOptions);
const axiosAuthClient = axios.create(defaultOptions);

let isRefreshing = false;
let refreshSubscribers = [];

function onAccessTokenRefreshed(newAccessToken) {
  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

axiosClient.interceptors.request.use(
  async (config) => {
    const adminToken = JSON.parse(
      localStorage.getItem("user-details-admin")
    )?.token;
    const token = JSON.parse(localStorage.getItem("user-details"))?.token;
    const path = window.location.href;
    config.headers.Authorization = `Bearer ${getDecryptedToken(
      path.includes(adminURL) ? adminToken : token
    )}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      const refreshPromise = new Promise((resolve) => {
        addRefreshSubscriber((newAccessToken) => {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          resolve(axios(originalRequest));
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshResponse = await axiosAuthClient.post(
            "/auth/refreshToken"
          );
          const { accessToken } = refreshResponse.data.response;

          await LocalStorageService.replaceToken(
            encryptAndStoreToken(accessToken)
          );
          onAccessTokenRefreshed(accessToken);
        } catch (refreshError) {
          LocalStorageService.removeFromLocalStorage("user-details");
          isRefreshing = false;
          return Promise.reject(refreshError);
        }

        isRefreshing = false;
      }

      return refreshPromise;
    }

    return Promise.reject(error);
  }
);

export { axiosAuthClient };
export default axiosClient;

export const appendFilesToFormData = (formData, files) => {
  if (files) {
    files.forEach((file, index) => {
      let fileType, blob, extension;

      if (file instanceof File) {
        fileType = file.type.startsWith("audio/") ? "audio" : "unknown";
        blob = file;
        extension = file.name.split(".").pop();
      } else if (typeof file === "string" && file.startsWith("data:")) {
        const byteString = atob(file.split(",")[1]);
        const mimeString = file.split(",")[0].split(":")[1].split(";")[0];
        fileType = mimeString.startsWith("video/") ? "video" : "image";

        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteArray[i] = byteString.charCodeAt(i);
        }

        blob = new Blob([byteArray], { type: mimeString });
        extension = fileType === "video" ? "mp4" : "jpg";
      } else {
        console.error("Unsupported file type:", file);
        return;
      }

      formData.append("file", blob, `file-${index}.${extension}`);
    });
  }
  return formData;
};
