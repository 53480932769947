import React from 'react';

const PauseButton = ({ width = 50, height = 50, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 50 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M24.9375 45.8337C36.4434 45.8337 45.7708 36.5063 45.7708 25.0003C45.7708 13.4944 36.4434 4.16699 24.9375 4.16699C13.4316 4.16699 4.10416 13.4944 4.10416 25.0003C4.10416 36.5063 13.4316 45.8337 24.9375 45.8337Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 17V33" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30 17V33" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default PauseButton;
