import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import Logo from "../../../../../assets/images/logo.svg";
import { closeModal } from "../../../../../store/slices/common/modal";
import { resendIdentityVerificationEmailAction } from "../../../../../store/middlewares/auth/session/auth";

const ModalVerifyEmail = (email, name) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleResend = () => {
    dispatch(resendIdentityVerificationEmailAction(email, name));
  };

  return (
    <>
      <div className="flex justify-center items-center pb-3 pt-5">
        <div className="flex flex-col">
          <div className="flex justify-center items-center pb-7">
            <img src={Logo} alt={"Logout"} className="w-60 " />
          </div>
          <h3 className="text-lg text-center text-[#1E405C] font-semibold pb-3">
            Email not verified
          </h3>
          <span className="pb-3">
            We’ve sent you a verification email upon registration. Please check
            your inbox and follow the instructions to verify your email.
          </span>
          <span className="text-sm">
            If you can’t find the email, click the button below to resend it and
            follow the instructions from the email.
          </span>
        </div>
      </div>
      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="m-auto px-12"
          onClick={handleResend}
        >
          Resend
        </Button>
      </div>
    </>
  );
};

export default ModalVerifyEmail;
