import axiosClient from "../../../../utils/api";

export const getLanguagesForWeb = async () => {
  try {
    const response = await axiosClient.request({
      url: "/display/getLanguage",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Languages Service", error);
    throw error;
  }
};

export const toggleDarkMode = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/display/darkMode",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Toggle Dark Service", error);
    throw error;
  }
};

export const updateLanguagesForWeb = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/display/updateLanguage",
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { ...data, id: payload.id };
  } catch (error) {
    console.log("Error at Update Languages Service", error);
    throw error;
  }
};
