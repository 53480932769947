import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMessages, getMessageThread, sendMessage, deleteMessage } from "../../../services/web/messages/messages.services";

export const getMessagesAction = createAsyncThunk(
  "messages/getMessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMessages();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch messages");
    }
  }
);

export const getMessageThreadAction = createAsyncThunk(
  "messages/getMessageThread",
  async (threadId, { rejectWithValue }) => {
    try {
      const response = await getMessageThread(threadId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch message thread");
    }
  }
);

export const sendMessageAction = createAsyncThunk(
  "messages/sendMessage",
  async (messageData, { rejectWithValue }) => {
    try {
      const response = await sendMessage(messageData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to send message");
    }
  }
);

export const deleteMessageAction = createAsyncThunk(
  "messages/deleteMessage",
  async (messageId, { rejectWithValue }) => {
    try {
      const response = await deleteMessage(messageId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to delete message");
    }
  }
); 