export const languages = {
  en: {
    Home: "Home",
    ExplorePage: "Explore Page",
    Notification: "Notification",
    WishRequests: "Wish Requests",
    LiveStream: "Livestream",
    MyOrder: "My Order",
    Messages: "Messages",
    UserLists: "User Lists",
    Bookmarks: "Bookmarks",
    Subscriptions: "Subscriptions",
    AddCard: "Add Card",
    MyProfile: "My Profile",
    More: "More",
    AllOrder: "All Order",
    MyCreatorShop: "My Creator Shop",
    PostLabels: "Post Labels",
    Vault: "Vault",
    Queue: "Queue",
    Statements: "Statements",
    Statistics: "Statistics",
    LogIn: "Log In",
    ForgotPassword: "Forgot Password",
    NoAccount: "Don't have An Account?",
    Register: "Register",
    EnterCode: "Enter Code",
    ResendOTP: "Resend OTP",
    EnterOTP: "Enter OTP",
    GetOTP: "Get OTP",
    BackToLogin: "Back To Login",
    Submit: "Submit",
    YourEmail: "Your Email",
    ResetPassword: "Reset Password",
    NewPassword: "New Password",
    ConfirmPassword: "Confirm Password",
    SeeAll: "See All",
    Suggestion: "Suggestion",
    Subscription: "Subscription",
    SubscriptionPromotionHeading: "Subscription Price and Promotions",
    SubscriptionPromotionPrice: "$10 per month, 2 subscription bundles",
    SubscriptionPromotionOffer: "Limited Offer 70% off for 31 days!",
    SubscriptionPromotionCondition:
      "For expired and new subscribers ends Mar 23",
    Friends: "Friends",
    Privacy: "Privacy",
    CookieNotice: "Cookie Notice",
    TermsOfService: "Terms of Service",
    All: "All",
    Free: "Free",
    Subscribe: "Subscribe",
    Unsubscribe: "Unsubsribe",
    AvailableNow: "Available Now",
    WishRequest: "Wish Request",
    NewPost: "New Post",
  },
};

export const returnLanguage = (lang) => {
  return languages[lang];
};
