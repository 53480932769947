import { createSlice } from "@reduxjs/toolkit";
import LocalStorageService from "./../../../../utils/localstorage-services";
import {
  loginAction,
  verifyTOTPAction,
  logoutAction,
  verifyIdentityAction,
} from "../../../middlewares/auth/session/auth";
import { encryptAndStoreToken } from "../../../../utils/CryptoJS";

export const webauthSlice = createSlice({
  name: "webauth",
  initialState: {
    webloginLoading: false,
    webloginMessage: "",
    webloginError: false,
    webloginSuccess: false,
    webloggedInUser: null,
    twoFaLoading: true,
    twoFaError: false,
    twoFaEmail: "",
    twoFaSuccess: false,
    twoFaMessage: "",
    hasTwoFa: false,
    weblogoutLoading: false,
    weblogoutMessage: "",
    weblogoutError: "",
    weblogoutUser: null,
    isTokenValid: true,
    isAdminTokenValid: true,
    verifyIdentityLoading: false,
    verifyIdentityMessage: "",
    verifyIdentityError: "",
    verifyIdentitySuccess: false,
  },
  reducers: {
    resetweblogin: (state) => {
      state.webloginLoading = false;
      state.webloginMessage = "";
      state.webloginError = "";
      state.webloggedInUser = null;
    },
    weblogoutSuccess: (state, action) => {
      let { message } = action.payload;
      LocalStorageService.removeFromLocalStorage("user-details");
      state.weblogoutLoading = false;
      state.weblogoutMessage = message;
      state.weblogoutError = "";
    },
    resetTwoFa: (state) => {
      state.twoFaLoading = true;
      state.twoFaError = false;
      state.twoFaEmail = "";
      state.twoFaSuccess = false;
      state.twoFaMessage = "";
      state.hasTwoFa = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAction.pending, (state) => {
        state.webloginLoading = true;
        state.webloginMessage = "";
        state.webloginError = false;
        state.webloginSuccess = false;
        state.webloggedInUser = null;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        let { response, email, message } = action.payload;
        state.webloginLoading = false;
        state.webloginMessage = message;
        if (response?.twoStepAuth === true) {
          state.hasTwoFa = true;
          state.twoFaEmail = email;
          return;
        }
        const localStorage = {
          user: { ...response, token: encryptAndStoreToken(response.token) },
        };
        LocalStorageService.loginWeb(localStorage);
        state.hasTwoFa = false;
        state.twoFaEmail = "";
        state.webloginSuccess = true;
        state.webloggedInUser = response;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.webloginMessage = action.payload.message;
        state.webloginLoading = false;
        state.webloginError = true;
        state.webloginSuccess = false;
        state.webloggedInUser = null;
      })
      .addCase(verifyTOTPAction.pending, (state) => {
        state.webloginLoading = true;
        state.webloginMessage = "";
        state.webloginError = false;
        state.webloggedInUser = null;
        state.twoFaSuccess = false;
        state.twoFaError = false;
      })
      .addCase(verifyTOTPAction.fulfilled, (state, action) => {
        let { response, status, message } = action.payload;
        state.twoFaLoading = false;
        if (status === 1) {
          const localStorage = {
            user: {
              ...response,
              token: encryptAndStoreToken(response?.token),
            },
          };
          LocalStorageService.loginWeb(localStorage);
          state.hasTwoFa = false;
          state.twoFaEmail = "";
          state.webloggedInUser = response;
          state.webloginMessage = "User login!";
          state.twoFaSuccess = true;
          state.twoFaError = false;
          return;
        }
        state.webloggedInUser = null;
        state.webloginMessage = message;
        state.twoFaMessage = message;
        state.twoFaSuccess = false;
        state.twoFaError = true;
      })
      .addCase(verifyTOTPAction.rejected, (state, action) => {
        state.webloginLoading = false;
        state.webloginError = action.payload.message;
        state.twoFaSuccess = false;
        state.twoFaError = true;
        state.twoFaMessage = action.payload.message;
      })
      .addCase(logoutAction.pending, (state) => {
        state.weblogoutLoading = true;
        state.weblogoutMessage = "";
        state.weblogoutError = "";
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        let { message } = action.payload;
        state.weblogoutLoading = false;
        state.weblogoutMessage = message;
        state.weblogoutError = "";
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.weblogoutLoading = false;
        state.weblogoutMessage = "";
        state.weblogoutError = action.payload;
      })
      .addCase(verifyIdentityAction.pending, (state) => {
        state.verifyIdentityLoading = true;
        state.verifyIdentityMessage = "";
        state.verifyIdentityError = "";
        state.verifyIdentitySuccess = false;
      })
      .addCase(verifyIdentityAction.fulfilled, (state, action) => {
        const { message } = action.payload;
        state.verifyIdentityLoading = false;
        state.verifyIdentityMessage = message;
        state.verifyIdentityError = "";
        state.verifyIdentitySuccess = true;
      })
      .addCase(verifyIdentityAction.rejected, (state, action) => {
        state.verifyIdentityLoading = false;
        state.verifyIdentityMessage = action.payload.message;
        state.verifyIdentityError = true;
        state.verifyIdentitySuccess = false;
      });
  },
});

export const { resetweblogin, resetTwoFa, weblogoutSuccess } =
  webauthSlice.actions;

export default webauthSlice.reducer;
