import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ShareBlackIcon from "./../../../../../assets/images/share-black.png";
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import InfoCircle from "./../../../../../assets/images/info-circle.svg";
import ModalReport from "../../userProfile/ModalReport";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../store/slices/common/modal";
import BlockTitle from "../../userProfile/ModalBlock/title";
import ModalBlock from "../../userProfile/ModalBlock";
import SaveToListTitle from "../../userProfile/ModalSaveToList/title";
import { ModalConfirmUnblock, ModalSaveToList } from "../../..";
import { ACCOUNT_URL } from "../../../../../utils/api";
import {
  hidePostsOfAUserAction,
  RestrictUserFromPostAction,
} from "../../../../../store/middlewares/web/post/posts";
import { upsertUserToUserListAction } from "../../../../../store/middlewares/admin/users/users";
import { openToast } from "../../../../../store/slices/common/toast";

const PostDropdown = ({ post, refresh, disabled }) => {
  let {
    id,
    isRestricted,
    isBlocked,
    isAddedInUserList,
    user: { username, id: userId },
  } = post;
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); //set Anchor for Menu
  };

  const handleClose = () => {
    setAnchorEl(null); //reset Anchor for Menu
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${ACCOUNT_URL}/${post?.user?.username}/status/${id}`);
    dispatch(
      openToast({
        type: "success",
        message: "Link copied to clipboard",
      })
    );
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
        size="small"
        aria-controls={open ? "more-detail" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <img
          src={ShareBlackIcon}
          alt="ShareIcon"
          width={24}
          className="img-dark-filter"
        />
      </IconButton>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
        size="small"
        aria-controls={open ? "more-detail" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <img
          src={MoreSquare}
          alt="MoreSquare"
          width={24}
          className="img-dark-filter"
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="more-detail"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleCopyLink}>Copy Link to Post</MenuItem>

        {isAddedInUserList && (
          <MenuItem
            onClick={() =>
              dispatch(upsertUserToUserListAction({ userId, refresh }))
            }
          >
            Remove from Lists
          </MenuItem>
        )}
        {!isAddedInUserList && (
          <MenuItem
            onClick={() =>
              dispatch(
                openModal({
                  title: <SaveToListTitle />,
                  content: (
                    <ModalSaveToList userId={userId} refresh={refresh} />
                  ),
                  hasCloseBtn: true,
                })
              )
            }
          >
            Add to Lists
          </MenuItem>
        )}
        {!isRestricted && (
          <MenuItem
            onClick={() => {
              dispatch(RestrictUserFromPostAction({ userId }));
            }}
          >
            <div className="flex items-center justify-between w-full">
              Restrict
              <Tooltip
                title={"You will not see messages or comments from this user."}
                placement="top"
              >
                <img
                  src={InfoCircle}
                  alt="InfoCircle"
                  className="img-dark-filter"
                />
              </Tooltip>
            </div>
          </MenuItem>
        )}
        {isRestricted && (
          <MenuItem
            onClick={() => dispatch(RestrictUserFromPostAction({ userId }))}
          >
            Unrestrict
          </MenuItem>
        )}
        {!isBlocked && (
          <MenuItem
            onClick={() => {
              dispatch(
                openModal({
                  title: <BlockTitle username={username} />,
                  content: <ModalBlock id={userId} />,
                })
              );
            }}
          >
            Block
          </MenuItem>
        )}
        {isBlocked && (
          <MenuItem
            onClick={() => {
              dispatch(
                openModal({
                  title: "UNBLOCK USER",
                  content: <ModalConfirmUnblock userId={userId} />,
                })
              );
            }}
          >
            Unblock
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            dispatch(
              openModal({
                title: "REPORT",
                hasCloseBtn: false,
                content: (
                  <ModalReport postId={id} type={"post"} userId={userId} />
                ),
              })
            );
          }}
        >
          Report
        </MenuItem>
        <MenuItem
          onClick={() => dispatch(hidePostsOfAUserAction({ id: userId }))}
        >
          {"Hide User's Post From Timeline"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default PostDropdown;
