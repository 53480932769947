import React from 'react';

const OptionsButton = ({ width = 28, height = 28, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 28 28" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M3.5 10.6286V17.3602C3.5 19.8336 3.5 19.8336 5.83333 21.4086L12.25 25.1186C13.2183 25.6786 14.7933 25.6786 15.75 25.1186L22.1667 21.4086C24.5 19.8336 24.5 19.8336 24.5 17.3719V10.6286C24.5 8.16691 24.5 8.16691 22.1667 6.59191L15.75 2.88191C14.7933 2.32191 13.2183 2.32191 12.25 2.88191L5.83333 6.59191C3.5 8.16691 3.5 8.16691 3.5 10.6286Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 17.5C15.933 17.5 17.5 15.933 17.5 14C17.5 12.067 15.933 10.5 14 10.5C12.067 10.5 10.5 12.067 10.5 14C10.5 15.933 12.067 17.5 14 17.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default OptionsButton;
