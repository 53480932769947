import axiosClient, { appendFilesToFormData } from "../../../../utils/api";

//Get Posts in Website
export const getPosts = async () => {
  try {
    const response = await axiosClient.request({
      url: "/post/getPost",
      method: "get",
    });
    let { data } = response;

    let tempArray = data?.response?.map((post) => {
      let tempLikeArray = post?.post_like?.map((like) => {
        return {
          id: like?.user?.id,
          profileImage: like?.user?.profileImage,
          name: like?.user?.name,
          createdAt: like?.createdAt,
          updatedAt: like?.updateAt,
        };
      });
      let tempCommentArray = post?.post_comment?.map((comment) => {
        return {
          id: comment?.id,
          profileImage: comment?.user?.profileImage,
          name: comment?.user?.name,
          comment: comment?.comment,
          userId: comment?.user?.id,
          createdAt: comment?.createdAt,
          updatedAt: comment?.updateAt,
        };
      });
      return {
        ...post,
        post_like: tempLikeArray,
        post_comment: tempCommentArray,
      };
    });
    return {
      response: tempArray,
      status: data?.status,
      message: data?.message,
    };
  } catch (error) {
    console.log("Error at get Posts Service", error);
    throw error;
  }
};
//Get Post Form Id in Website
export const getPostFromId = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: `/post/getPostDetails/${payload.id}`,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at get Posts Service", error);
    throw error;
  }
};

export const getPostsWithCursor = async (obj) => {
  try {
    const response = await axiosClient.request({
      url: "/post/posts",
      method: "get",
      params: { cursor: obj.cursor },
    });
    return response.data;
  } catch (err) {
    console.log("Error at get Post With Cursor Service", err);
  }
};
export const getArchivedPostsWithCursor = async (obj) => {
  try {
    const response = await axiosClient.request({
      url: "/post/posts",
      method: "get",
      params: { cursor: obj.cursor, archived: true },
    });
    return response.data;
  } catch (err) {
    console.log("Error at get Post With Cursor Service", err);
  }
};

export const getPostsByUserIdWebsite = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/getPostByUser",
      method: "post",
      data: payload,
      params: { ...(payload?.cursor ? { cursor: payload.cursor } : {}) },
    });
    return response.data;
  } catch (err) {
    console.log("Error at get all posts Service", err);
    throw err;
  }
};

//Get Folder Wise Posts In Bookmarks
export const getFolderWisePosts = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: "/post/getBookmarkFolders/posts",
      method: "post",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.log("Error at get Folder Wise Posts Service", error);
    throw error;
  }
};

//Add A Post
export const createPosts = async (payload, files = null) => {
  try {
    const formData = new FormData();
    if (payload) {
      formData.append("data", JSON.stringify(payload));
    }

    if (files) {
      files.forEach((file, index) => {
        let fileType, blob, extension;

        if (file instanceof File) {
          fileType = file.type.startsWith("audio/") ? "audio" : "unknown";
          blob = file;
          extension = file.name.split(".").pop();
        } else if (typeof file === "string" && file.startsWith("data:")) {
          const byteString = atob(file.split(",")[1]);
          const mimeString = file.split(",")[0].split(":")[1].split(";")[0];
          fileType = mimeString.startsWith("video/") ? "video" : "image";

          const byteArray = new Uint8Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
          }

          blob = new Blob([byteArray], { type: mimeString });
          extension = fileType === "video" ? "mp4" : "jpg";
        } else {
          console.error("Unsupported file type:", file);
          return;
        }

        formData.append("file", blob, `file-${index}.${extension}`);
      });
    }

    // Send the FormData request
    const response = await axiosClient.request({
      url: "/post/createPost",
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  } catch (error) {
    console.log("Error at Create Post Service", error);
    throw error;
  }
};

//Like Dislike Posts
export const likeDislikePosts = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/updateLike",
      method: "post",
      data: payload,
    });
    let {
      data: {
        response: { likeInfo },
        status,
        message,
      },
    } = response;
    let tempArray = [];
    if (likeInfo?.length > 0) {
      tempArray = likeInfo?.map((like) => {
        return {
          id: like?.user?.id,
          profileImage: like?.user?.profileImage,
          name: like?.user?.name,
          createdAt: like?.createdAt,
          updatedAt: like?.updateAt,
        };
      });
    }
    return { post_like: tempArray, status, message, postId: payload.postId };
  } catch (error) {
    console.log("Error at Like/Dislike posts Service", error);
    throw error;
  }
};

//Add Comment
export const addComment = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/addComment",
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { ...data, postId: payload.postId };
  } catch (error) {
    console.log("Error at Add Comment Service", error);
    throw error;
  }
};

//update Comment
export const updateComment = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/updateComment",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Update Comment Service", error);
    throw error;
  }
};

//delete Comment From A Post
export const deleteComment = async (payload) => {
  let { postId, commentId } = payload;
  try {
    const response = await axiosClient.request({
      url: "/post/deleteComment",
      method: "post",
      data: { commentId },
    });
    let { data } = response;
    return { ...data, postId, commentId };
  } catch (error) {
    console.log("Error at delete Comment Service", error);
    throw error;
  }
};

//Get Post Of A User in User Detail Page in Admin Panel
export const getPostsByUserId = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: "/admin/post/getUserPost",
      method: "post",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.log("Error at get Posts By User Service", error);
    throw error;
  }
};
//Hide Post Of A User In Website
export const hidePostsOfAUser = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: "/hideUser/add",
      method: "post",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.log("Error at Hide Posts From Timeline Service", error);
    throw error;
  }
};

//Get All Posts in Admin Panel
export const getAllPostsAdmin = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: "/admin/post/getAllPost",
      method: "post",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.log("Error at get All Posts For Admin Service", error);
    throw error;
  }
};

//Get All Deleted Posts in Admin Panel
export const getDeletedPostsAdmin = async () => {
  try {
    const res = await axiosClient.request({
      url: "/admin/post/getDeletedPost",
      method: "get",
    });
    return res.data;
  } catch (error) {
    console.log("Error at get Deleted Posts For Admin Service", error);
    throw error;
  }
};

//Ban Post By Admin
export const banPostAdmin = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: "/admin/post/banPost",
      method: "post",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.log("Error at Ban Post By Admin Service", error);
    throw error;
  }
};

//Delete Post By Admin
export const deletePostAdmin = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: "/admin/post/deletePost",
      method: "post",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.log("Error at Delete Post by Admin Service", error);
    throw error;
  }
};

//Delete Post In Web
export const deletePost = async (payload) => {
  try {
    const res = await axiosClient.request({
      url: `/post/deletePost`,
      method: "delete",
      params: { postId: payload.postId },
    });
    return res.data;
  } catch (error) {
    console.log("Error at Delete Post by Admin Service", error);
    throw error;
  }
};

//Bookmark/Unbookmark
export const upsertBookmark = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/upsertBookmarks",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Create Bookmark Service", error);
    throw error;
  }
};

//Restrict User From Post
export const restrictUserFromPost = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/restrictedUser/add",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Restrict User From Post", error);
    throw error;
  }
};

//Restrict User From Post
export const answerPoll = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/pollAnswer",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Answer Poll", error);
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    }
    throw error;
  }
};

//Restrict User From Post
export const answerQuiz = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/quizAnswer",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Answer Poll", error);
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    }
    throw error;
  }
};

export const getStories = async () => {
  try {
    const response = await axiosClient.request({
      url: "/post/getStories",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at get Stories Service", error);
    throw error;
  }
};

export const createPostView = async (postId) => {
  try {
    const response = await axiosClient.request({
      url: `/post/createPostView/${postId}`,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.log("Error at createPostView Service", error);
    throw error;
  }
};

export const getPostComments = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/comments",
      method: "get",
      params: { cursor: payload.cursor, postId: payload.postId },
    });
    return response.data;
  } catch (error) {
    console.log("Error at getPostComments Service", error);
    throw error;
  }
};

export const updateUserPost = async (payload) => {
  let formData = new FormData();
  const files =
    payload.data.files && payload.data.files.length > 0
      ? payload.data.files
      : null;
  delete payload.data.files;

  if (payload.data) {
    formData.append("data", JSON.stringify(payload.data));
  }

  formData = appendFilesToFormData(formData, files);

  const response = await axiosClient.request({
    url: `/post/${payload.id}`,
    method: "put",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const getScheduledPosts = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/schedulePost",
      method: "get",
      params: { month: payload.month, year: payload.year },
    });
    return response.data;
  } catch (error) {
    console.log("Error at get Scheduled Posts Service", error);
    throw error;
  }
};

export const getActiveExplorePosts = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/activeExplorePosts",
      method: "get",
      params: {
        cursor: payload.cursor,
        pageSize: payload.pageSize,
        batchSize: payload.batchSize,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error at get Active Explore Posts Service", error);
    throw error;
  }
};

export const getPostStats = async (postId) => {
  try {
    const response = await axiosClient.request({
      url: `/post/stats/${postId}`,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at get Post Stats Service", error);
    throw error;
  }
};

export const generateVideoUploadPreSignedURL = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/generateVideoUploadPreSignedUrl",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at generate Video PreSigned URL Service", error);
    throw error;
  }
};

export const postVideoWatchTime = async (payload) => {
  /*
  payload : 
  {
    postMediaId: Joi.string().uuid().required(),
    postId: Joi.string().uuid().required(),
    watchTime: Joi.number().required(),
  }
  */
  try {
    const response = await axiosClient.request({
      url: "/post/video-watch-time",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at post Video Watch Time Service", error);
    throw error;
  }
};

export const deleteBookmark = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: `/post/deleteBookmark/${payload.bookmarkId}`,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.log("Error at delete Bookmark Service", error);
    throw error;
  }
};

export const deleteStory = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/post/story/${payload.postId}/${payload.mediaId}`,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.log("Error at delete Story Service", error);
    throw error;
  }
};

export const likeStoryPostMedia = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/post/toggleLikeStoryMedia/${payload.postId}/${payload.postMediaId}`,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.log("Error at like Story Media Service", error);
    throw error;
  }
};
