import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
    name: 'toast',
    initialState: {
        isOpen: false,
        type: "",
        message: "",
        duration: 2000
    },
    reducers: {
        openToast: (state, { payload }) => {
            let { type, message, duration } = payload
            state.isOpen = true;
            state.type = type;
            state.message = message;
            state.duration = duration || 2000;
        },
        closeToast: (state) => {
            state.isOpen = false;
            state.type = "";
            state.message = "";
            state.duration = 2000;
        },
    },
});

export const {
    openToast, closeToast
} = toastSlice.actions;

export default toastSlice.reducer;