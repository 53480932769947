import { Box } from '@mui/material';
import React from 'react';
import Logo from '../../../assets/images/logo.svg';

function Loader() {

    return (
        <Box className="flex items-center justify-center h-screen loading-img">
            <img src={Logo} width={600} alt={"loading.."} />
        </Box>
    );
}

export default Loader;