import React from "react";

const PhotoIcon = ({ white = false }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49984 18.8333H12.4998C16.6665 18.8333 18.3332 17.1666 18.3332 13V7.99996C18.3332 3.83329 16.6665 2.16663 12.4998 2.16663H7.49984C3.33317 2.16663 1.6665 3.83329 1.6665 7.99996V13C1.6665 17.1666 3.33317 18.8333 7.49984 18.8333Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50016 8.83333C8.42064 8.83333 9.16683 8.08714 9.16683 7.16667C9.16683 6.24619 8.42064 5.5 7.50016 5.5C6.57969 5.5 5.8335 6.24619 5.8335 7.16667C5.8335 8.08714 6.57969 8.83333 7.50016 8.83333Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.2251 16.2917L6.33343 13.5333C6.99176 13.0917 7.94176 13.1417 8.53343 13.65L8.80843 13.8917C9.45843 14.45 10.5084 14.45 11.1584 13.8917L14.6251 10.9167C15.2751 10.3583 16.3251 10.3583 16.9751 10.9167L18.3334 12.0833"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhotoIcon;
