import React from "react";
import "./assets/styles/darkmode.scss";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import "@fontsource/poppins";
import "./assets/scss/themes.scss";
import ToastWrapper from "./newComponents/atoms/ToastWrapper";
import { AdminModal, Modal, ThemeProvider } from "./newComponents/atoms";
import WebNavigation from "./navigation/webNavigation";
import AdminNavigation from "./navigation/adminNavigation";
import CustomizableModalContainer from "./newComponents/molecules/web/common/CustomizableModalContainer";
import UnifiedNavigation from "./navigation/unifiedNavigation";
import { useNotification } from './hooks/useNotification';

function App() {
  // init notifications
  useNotification();

  return (
    <>
      <Modal />
      <AdminModal />
      <ToastWrapper />
      <CustomizableModalContainer />
      <Routes>
        <Route
          path="/*"
          element={
            <ThemeProvider>
              <UnifiedNavigation />
            </ThemeProvider>
          }
        />
        {/* <Route
          path="/user/*"
          element={
            <ThemeProvider>
              <UserNavigation />
            </ThemeProvider>
          }
        />
        <Route
          path="/creator/*"
          element={
            <ThemeProvider>
              <CreatorNavigation />
            </ThemeProvider>
          }
        /> */}
        <Route
          path="/*"
          element={
            <ThemeProvider>
              <WebNavigation />
            </ThemeProvider>
          }
        />
        <Route path="/admin/*" element={<AdminNavigation />} />
      </Routes>
    </>
  );
};

export default App;
