import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword } from "../../../middlewares/auth/forgotPassword/forgot";

export const webforgotSlice = createSlice({
  name: "webforgot",
  initialState: {
    webforgotLoading: false,
    webforgotMessage: "",
    webforgotError: false,
    webforgotSuccess: false,
    webforgotEmail: null,
  },
  reducers: {
    resetwebforgot: (state) => {
      state.webforgotLoading = false;
      state.webforgotMessage = "";
      state.webforgotError = false;
      state.webforgotSuccess = false;
      state.webforgotEmail = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.webforgotLoading = false;
        state.webforgotMessage = "";
        state.webforgotError = false;
        state.webforgotSuccess = false;
        state.webforgotEmail = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        const {
          payload: { email, message },
        } = action;
        state.webforgotMessage = message;
        state.webforgotLoading = false;
        state.webforgotError = false;
        state.webforgotSuccess = true;
        state.webforgotEmail = { email };
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        let { message } = action.payload;
        state.webforgotLoading = false;
        state.webforgotMessage = message;
        state.webforgotError = true;
        state.webforgotSuccess = false;
        state.webforgotEmail = null;
      });
  },
});

export const { resetwebforgot } = webforgotSlice.actions;

export default webforgotSlice.reducer;
