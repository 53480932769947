import { createSlice } from "@reduxjs/toolkit";
import { getVaultMediaAction } from "../../middlewares/web/vault/vault";

const initialState = {
  loading: false,
  error: null,
  media: [],
  cursor: null,
  hasMore: true,
  // Sorting state
  sortOption: "Name",
  sortOrder: "Ascending",
  // Selection state
  selectedMedia: [],
  // folders
  folders: [],
  foldersLoading: false,
  foldersError: null,
};

const getUniqueMedia = (existingMedia, newMedia) => {
  if (!Array.isArray(newMedia)) return existingMedia;
  
  const mediaMap = new Map();

  existingMedia.forEach(item => {
    mediaMap.set(item.vaultId, item);
  });
  
  newMedia.forEach(item => {
    mediaMap.set(item.vaultId, item);
  });
  
  return Array.from(mediaMap.values());
};

export const vaultSlice = createSlice({
  name: "vault",
  initialState,
  reducers: {
    clearVaultMedia: (state) => {
      state.media = [];
      state.cursor = null;
      state.hasMore = true;
      state.selectedMedia = [];
    },
    setSortOption: (state, { payload }) => {
      state.sortOption = payload;
    },
    setSortOrder: (state, { payload }) => {
      state.sortOrder = payload;
    },
    toggleMediaSelection: (state, { payload }) => {
      const index = state.selectedMedia.indexOf(payload);
      if (index > -1) {
        state.selectedMedia.splice(index, 1);
      } else {
        state.selectedMedia.push(payload);
      }
    },
    addMediaToVault: (state, { payload }) => {
      const newMedia = payload?.response;
      if (newMedia) {
        // REMOVE DUPLICATES
        state.media = state.media.filter(item => item.vaultId !== newMedia.vaultId);
        state.media.unshift(newMedia);
      }
    },
    setVaultFolders: (state, { payload }) => {
      state.folders = payload.folders;
      state.foldersLoading = false;
      state.foldersError = null;
    },
    setVaultFoldersLoading: (state) => {
      state.foldersLoading = true;
      state.foldersError = null;
    },
    setVaultFoldersError: (state, { payload }) => {
      state.foldersLoading = false;
      state.foldersError = payload;
    },
    addVaultFolder: (state, { payload }) => {
      const newFolder = {
        id: payload.id,
        name: payload.folderName,
        gifCount: 0,
        videoCount: 0,
        photoCount: 0,
        audioCount: 0,
        avatars: []
      };
      state.folders = [newFolder, ...state.folders];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVaultMediaAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVaultMediaAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        const mediaData = payload?.response?.media || [];
        state.media = getUniqueMedia(state.media, mediaData);
        state.cursor = mediaData.length > 0 ? mediaData[mediaData.length - 1].vaultId : null;
        state.hasMore = mediaData.length > 0;
      })
      .addCase(getVaultMediaAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || "Failed to load vault media";
      });
  },
});

export const { 
  clearVaultMedia, 
  setSortOption, 
  setSortOrder, 
  toggleMediaSelection,
  addMediaToVault,
  setVaultFolders,
  setVaultFoldersLoading,
  setVaultFoldersError,
  addVaultFolder
} = vaultSlice.actions;

export default vaultSlice.reducer;
