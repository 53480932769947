import React from 'react';

const FullScreenButton = ({ width = 28, height = 28, color = "white", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 28 28" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M8.4 18.2H1.4C0.56 18.2 0 18.76 0 19.6C0 20.44 0.56 21 1.4 21H7V26.6C7 27.44 7.56 28 8.4 28C9.24 28 9.8 27.44 9.8 26.6V19.6C9.8 18.76 9.24 18.2 8.4 18.2ZM8.4 0C7.56 0 7 0.56 7 1.4V7H1.4C0.56 7 0 7.56 0 8.4C0 9.24 0.56 9.8 1.4 9.8H8.4C9.24 9.8 9.8 9.24 9.8 8.4V1.4C9.8 0.56 9.24 0 8.4 0ZM19.6 9.8H26.6C27.44 9.8 28 9.24 28 8.4C28 7.56 27.44 7 26.6 7H21V1.4C21 0.56 20.44 0 19.6 0C18.76 0 18.2 0.56 18.2 1.4V8.4C18.2 9.24 18.76 9.8 19.6 9.8ZM26.6 18.2H19.6C18.76 18.2 18.2 18.76 18.2 19.6V26.6C18.2 27.44 18.76 28 19.6 28C20.44 28 21 27.44 21 26.6V21H26.6C27.44 21 28 20.44 28 19.6C28 18.76 27.44 18.2 26.6 18.2Z" fill={color}/>
    </svg>
  );
};

export default FullScreenButton;
