import { Button, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { MuiTelInput } from 'mui-tel-input';
import { Phone } from "../../../../../../utils/validation/ChangePhoneNumberForm";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { 
  verifyPhoneNumber, 
  validateVerificationCode 
} from "../../../../../../store/services/web/profile/phoneNumber.service";
import { openToast } from "../../../../../../store/slices/common/toast";

const ModalChangePhoneNumber = () => {
  const { me } = useSelector((state) => state?.me);
  const [intialVal, setInitialVal] = useState(null);
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (me) {
      let tempObj = {
        phone: me?.phone || "",
      };
      setInitialVal(tempObj);
    }
  }, [me]);

  const schema = Yup.object({
    phone: Phone,
  });

  const handleClose = () => {
    formik.resetForm();
    setShowVerification(false);
    setVerificationCode('');
    dispatch(closeModal());
  };

  const handlePhoneNumberSubmit = async (values) => {
    if (!values?.phone) return;
    
    setIsSubmitting(true);
    try {
      const response = await verifyPhoneNumber(values.phone.replaceAll(' ', ''));
      setShowVerification(true);
      dispatch(openToast({ 
        type: "success", 
        message: "Verification code sent to your mobile phone!" 
      }));
    } catch (error) {
      console.log("Error response:", error.response?.data);
      
      if (error.response?.data) {
        dispatch(openToast({ 
          type: "error", 
          message: error.response.data.message || "There was an error trying to verify the phone number, please contact the support" 
        }));
      } else {
        dispatch(openToast({ 
          type: "error", 
          message: "There was an error trying to verify the phone number, please contact the support" 
        }));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerificationSubmit = async () => {
    try {
      await validateVerificationCode(verificationCode);

      dispatch(openToast({ 
        type: "success", 
        message: "Phone number updated successfully!" 
      }));
      handleClose();
    } catch (error) {
      console.log("Error response:", error.response?.data);
      
      if (error.response?.data) {
        dispatch(openToast({ 
          type: "error", 
          message: error.response.data.message || "Invalid verification code" 
        }));
      } else {
        dispatch(openToast({ 
          type: "error", 
          message: "There was an error validating the code, please try again" 
        }));
      }
    }
  };

  const formik = useFormik({
    initialValues: intialVal,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handlePhoneNumberSubmit,
  });

  const handlePhoneChange = (value) => {
    formik.setFieldValue('phone', value);
  };

  const handleVerificationCodeInput = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '').slice(0, 4);
    setVerificationCode(value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: 1 },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: "100%",
          }}
        >
          <MuiTelInput
            value={formik.values?.phone || ''}
            onChange={handlePhoneChange}
            onBlur={formik.handleBlur}
            error={formik.errors.phone && formik.touched.phone}
            helperText={formik.touched.phone && formik.errors.phone}
            fullWidth
            defaultCountry="US"
            preferredCountries={['US', 'GB', 'CA']}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '16px',
              }
            }}
          />
        </Box>
        
        <Button 
          type="submit"
          variant="contained" 
          disabled={isSubmitting}
          sx={{
            minWidth: '100px',
            height: '56px',
            borderRadius: '16px',
            backgroundColor: '#2196f3',
            color: 'white',
            '&:hover': {
              backgroundColor: '#1976d2',
            },
            '&.Mui-disabled': {
              backgroundColor: '#cccccc',
              color: '#666666'
            }
          }}
        >
          {isSubmitting ? 'Sending...' : 'Save'}
        </Button>
      </Box>

      {showVerification && (
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            alignItems: 'center'
          }}
        >
          <TextField
            value={verificationCode}
            onChange={handleVerificationCodeInput}
            placeholder="Enter 4-digit code"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '16px',
              }
            }}
          />
          <Button
            onClick={handleVerificationSubmit}
            variant="contained"
            disabled={verificationCode.length !== 4}
            sx={{
              minWidth: '100px',
              height: '56px',
              borderRadius: '16px',
              backgroundColor: '#2196f3',
              color: 'white',
              '&:hover': {
                backgroundColor: '#1976d2',
              }
            }}
          >
            Verify
          </Button>
        </Box>
      )}
      
      <Box 
        sx={{ 
          display: "flex",
          justifyContent: "center",
          mt: 3,
          gap: 2 
        }}
      >
        <Button variant="contained" className='m-auto white-btn' onClick={handleClose}>Cancel</Button>
      </Box>
    </form>
  );
};

export default ModalChangePhoneNumber;
