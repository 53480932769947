function loginUser({ user }) {
  localStorage.setItem("user-details-admin", JSON.stringify(user));
}

function removeFromLocalStorage(name) {
  localStorage.removeItem(`${name}`);
}

function loginWeb({ user }) {
  localStorage.setItem("user-details", JSON.stringify(user));
}

async function replaceToken(encryptedToken) {
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  if (userDetails) {
    userDetails.token = encryptedToken;
    localStorage.setItem("user-details", JSON.stringify(userDetails));
  }
}

const LocalStorageService = {
  loginUser,
  loginWeb,
  removeFromLocalStorage,
  replaceToken,
};

export default LocalStorageService;
