import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Checkbox,
  Skeleton,
} from "@mui/material";
import AudioIcon from "../../../../../assets/SVG/VaultTabs/AudioIcon";
import PhotoIcon from "../../../../../assets/SVG/VaultTabs/PhotoIcon";
import VideoIcon from "../../../../../assets/SVG/VaultTabs/VideoIcon";
import GifIcon from "../../../../../assets/SVG/VaultTabs/GifIcon";
import VideoPlayer from "../media/VideoPlayer";
import AudioPlayer from "../media/AudioPlayer";
import { formatDuration } from "../../../../../utils/formatDuration";
import { useDraggable } from "@dnd-kit/core";

const IMAGE_HEIGHT = 150;

const MediaItem = ({
  type,
  thumbnail,
  date,
  selected,
  onSelect,
  id,
  isMultiDrag,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [duration, setDuration] = useState(null);

  const handleDurationChange = (newDuration) => {
    setDuration(newDuration);
  };

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: id,
      disabled: !selected,
    });

  const style = {
    transform:
      isDragging && !isMultiDrag && transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : undefined,
    opacity: isDragging && !isMultiDrag ? 0 : 1,
    cursor: selected ? "grab" : "pointer",
  };

  const getIcon = () => {
    switch (type) {
      case "video":
        return <VideoIcon fontSize="small" />;
      case "audio":
        return <AudioIcon fontSize="small" />;
      case "image":
        return <PhotoIcon fontSize="small" />;
      case "gif":
        return <GifIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const renderMedia = () => {
    if (type === "video") {
      return (
        <Box
          sx={{
            width: 150,
            height: IMAGE_HEIGHT,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <VideoPlayer
            src={thumbnail}
            onDurationChange={handleDurationChange}
          />
        </Box>
      );
    }

    if (type === "audio") {
      return (
        <Box
          sx={{
            width: 150,
            height: IMAGE_HEIGHT,
            borderRadius: 2,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
          }}
        >
          <AudioPlayer
            src={thumbnail}
            onDurationChange={handleDurationChange}
          />
        </Box>
      );
    }

    return (
      <>
        {!imageLoaded && (
          <Skeleton
            variant="rectangular"
            width={150}
            height={150}
            sx={{ borderRadius: 2 }}
          />
        )}
        <CardMedia
          component="img"
          image={thumbnail}
          alt={`${type} thumbnail`}
          sx={{
            borderRadius: 2,
            display: imageLoaded ? "block" : "none",
            width: "100%",
            height: IMAGE_HEIGHT,
          }}
          onLoad={() => setImageLoaded(true)}
        />
      </>
    );
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={onSelect}
      sx={{
        position: "relative",
        borderRadius: 2,
        border: selected ? "2px solid #1976d2" : "2px solid #F2F2F2",
        p: 1,
        cursor: "pointer",
        "&:hover": {
          boxShadow: 4,
        },
      }}
      elevation={0}
    >
      {renderMedia()}
      <Checkbox
        checked={selected}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          bgcolor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "25%",
          padding: "4px",
          "& .MuiSvgIcon-root": {
            fontSize: 20,
          },
        }}
        readOnly
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {getIcon()}
          {duration && (
            <Typography variant="body2">{formatDuration(duration)}</Typography>
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          {date}
        </Typography>
      </Box>
    </Card>
  );
};

MediaItem.propTypes = {
  type: PropTypes.oneOf(["video", "audio", "image", "gif"]).isRequired,
  thumbnail: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

MediaItem.defaultProps = {
  selected: false,
  onSelect: () => {},
};

export default MediaItem;
