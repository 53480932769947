import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

const timeOptions = [
  { value: 0, label: '' },
  { value: 1, label: '6h since my last post' },
  { value: 2, label: '12h since my last post' },
  { value: 3, label: '24h since my last post' },
  { value: 4, label: '48h since my last post' },
];

const TimeDropdown = ({ value, onChange, className }) => {
  return (
    <FormControl fullWidth className={className}>
      <Select
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--bordercolor)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--primary)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--primary)',
          },
        }}
      >
        {timeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeDropdown; 