import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserName } from "../../../../../../utils/validation/ChangeUserNameForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../atoms";
import { closeModal } from "../../../../../../store/slices/common/modal";
import { useIssueApiCall } from "../../../../../../hooks/backendApiCalls/useIssueAnApiCall";
import { updateMeAction } from "../../../../../../store/middlewares/web/profile/myProfile/me";

const ModalChangeUsername = () => {
  const { me } = useSelector((state) => state?.me);
  const dispatch = useDispatch();

  const [username, setUsername] = useState(me?.username || "");

  const executeApiCall = useIssueApiCall(async (error) => {
    if (!error) {
      dispatch(closeModal());
    }
    formik.resetForm();
  });

  useEffect(() => {
    if (me?.username) {
      setUsername(me.username);
    }
  }, [me]);

  const schema = Yup.object({
    username: UserName,
  });

  const handleClose = () => {
    formik.resetForm();
    dispatch(closeModal());
  };

  const handleSubmit = async (values) => {
    await executeApiCall(updateMeAction(values));
  };

  const formik = useFormik({
    initialValues: {
      username: username,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    //title - CHANGE USERNAME
    <form onSubmit={formik.handleSubmit}>
      <Input
        name="username"
        value={formik.values.username}
        onChange={formik.handleChange}
        placeholder="Enter Username"
        onBlur={formik.handleBlur}
        id="username"
        error={formik.errors.username && formik.touched.username}
        errorMsg={formik.errors.username}
      />

      <div className="flex justify-end mt-6 gap-3">
        <Button
          variant="contained"
          className="m-auto white-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="m-auto px-12">
          Save
        </Button>
      </div>
    </form>
  );
};

export default ModalChangeUsername;
