import React from "react";
import BotLink from "./../../../../../../assets/images/bot-link.svg";
import { getTelegramBotLink } from "../../../../../../store/services/web/profile/account.services";
import { useDispatch } from "react-redux";
import { openToast } from "../../../../../../store/slices/common/toast/index.js";

const ModalTelegramBot = () => {
  const dispatch = useDispatch();
  const handleBotLinkClick = async () => {
    try {
      const response = await getTelegramBotLink();
      if(response?.response?.tgBotConnectLink){
        navigator.clipboard.writeText(response?.response?.tgBotConnectLink);
        dispatch(openToast({ type: "success", message: "Bot Link Copied" }));
        window.open(response?.response?.tgBotConnectLink, '_blank');
      }
    } catch (error) {
      dispatch(openToast({ type: "error", message: "Failed to get Telegram bot connection link" }));
    }
  };

  return (
    <div 
      className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3"
      onClick={handleBotLinkClick}
      style={{ cursor: 'pointer' }}
    >
      <div>
        <p className="font-18 font-medium text-darkblue">Get Bot Link</p>
        <p>Please do not share this link with anyone</p>
      </div>
      <img src={BotLink} alt="BotLink" />
    </div>
  );
};

export default ModalTelegramBot;
