import React from "react";
import Verified from "./../../../../../assets/images/verified.svg";

function UserProfileImage({ src, name, isVerified, onClick, size = "default" }) {
  const sizeClasses = {
    small: "max-h-[40px] max-w-[40px] h-[40px] w-[40px]",
    default: "max-h-[60px] max-w-[60px] h-[60px] w-[60px]",
    large: "max-h-[80px] max-w-[80px] h-[80px] w-[80px]"
  };

  return (
    <div
      className={`relative cursor-pointer ${sizeClasses[size]} rounded-[50%] border-2 border-[#2799F6]`}
      onClick={onClick}
    >
      <img
        src={src}
        alt={name}
        className="w-full h-full object-cover rounded-[50%] pointer-events-none"
      />
      {isVerified && (
        <div className={`verified-bg ${size === 'small' ? 'scale-75' : ''}`}>
          <img src={Verified} alt="Verified" />
        </div>
      )}
    </div>
  );
}

export default UserProfileImage;
