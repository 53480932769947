/* eslint-disable no-undef */
import { Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserBlank from "./../../../assets/images/blank.png";
import { removeCommentsAction } from "../../../store/middlewares/web/post/posts";
import { NavLink } from "react-router-dom";
import { timeAgo } from "../../../utils/timeAgo";

const SingleComment = ({ comment, postId, postCreatorUserId }) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(removeCommentsAction({ commentId: id, postId }));
  };

  const { me } = useSelector((state) => state.me);

  const canDelete = comment.user.id === me?.id || postCreatorUserId === me?.id;
  // const canEdit = (comment?.userId === me?.id)
  return (
    <>
      <div
        className={"flex gap-3 items-start py-2 bg-white mb-4"}
        key={comment?.id}
      >
        <Box className="min-h-[44px] h-[44px] min-w-[44px] w-[44px] mr-1">
          <img
            src={
              comment?.profileImage
                ? `${process.env.REACT_APP_AWS_URL}${comment?.profileImage}`
                : UserBlank
            }
            alt={comment?.user.username}
            className="w-full h-full rounded-[50%] object-cover pointer-events-none"
          />
        </Box>
        <div className="flex flex-col grow">
          <NavLink
            className="comment-username cursor-pointer"
            to={
              comment?.userId === me?.id
                ? "/my-profile"
                : `/profile/${comment?.user.username}`
            }
          >
            <strong>{comment?.user.username}</strong>{" "}
            <span className="text-xs text-gray-500">
              {timeAgo(comment?.createdAt)}
            </span>
          </NavLink>
          <div className="comment-text text-black">{comment?.text} </div>
        </div>
        {/* {canDelete && <IconButton onClick={() => { handleDelete(comment?.id) }}><Delete color='error' /> </IconButton>} */}
      </div>
    </>
  );
};

export default SingleComment;
