import React from 'react';
import styles from './styles.module.css';
import PlayButton from '../../../../../../assets/SVG/media/videoPlayer/PlayButton';
import PauseButton from '../../../../../../assets/SVG/media/videoPlayer/PauseButton';
import BackwardsButton from '../../../../../../assets/SVG/media/videoPlayer/BackwardsButton';
import ForwardButton from '../../../../../../assets/SVG/media/videoPlayer/ForwardButton';

const PlaybackControls = ({ isPlaying, onPlay, onNext, onPrevious, size }) => {
  return (
    <div className={styles.playbackControls}>
      <button 
        className={styles.controlButton}
        onClick={onPrevious}
      >
        <BackwardsButton height={size} width={size}/>
      </button>

      <button 
        className={styles.playButton}
        onClick={onPlay}
      >
        {isPlaying ? <PauseButton height={size} width={size}/> : <PlayButton height={size} width={size}/>}
      </button>

      <button 
        className={styles.controlButton}
        onClick={onNext}
      >
        <ForwardButton height={size} width={size}/>
      </button>
    </div>
  );
};

export default PlaybackControls;
