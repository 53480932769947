import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import * as Yup from "yup";

import React, { useEffect } from "react";
import { closeModal } from "../../../../../store/slices/common/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  AddReportAction,
  GetReportReasonsAction,
} from "../../../../../store/middlewares/admin/cms/reportReasons";
import { Reason } from "../../../../../utils/validation/AddReportReasonForm";
import { useFormik } from "formik";

function ModalReport({ userId = 0, type, postId = 0 }) {
  const dispatch = useDispatch();
  const { reasons } = useSelector((state) => state.reportReasons);

  const ReportReasonSchema = Yup.object({
    reason: Reason,
  });

  const handleSubmit = (values) => {
    //On Submit Form
    const { reason } = values;
    let tempObj = { reportReasonId: reason, type };
    if (type === "user") {
      tempObj = { ...tempObj, userId };
    }
    if (type === "post") {
      tempObj = { ...tempObj, postId };
    }
    dispatch(AddReportAction(tempObj));
  };

  // Form Management
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: ReportReasonSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(GetReportReasonsAction({ type }));
  }, []);

  const handleChange = (event) => {
    formik.setFieldValue("reason", event.target.value);
  };

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    //title = REPORT no close btn
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormControl className="w-full">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="reason"
            value={formik.values.reason}
            onChange={handleChange}
          >
            {reasons?.map((reason) => {
              return (
                <div
                  className="site-notification flex items-center justify-center pl-2 pr-4 py-2 border-radius-10 gap-3 w-full"
                  key={reason?.reason}
                >
                  <FormControlLabel
                    className="w-full"
                    value={reason?.id}
                    control={<Radio sx={{ paddingTop: "0 !important" }} />}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>{reason.reason}</span>
                      </Box>
                    }
                  />
                </div>
              );
            })}
          </RadioGroup>
          {formik.errors.reason && formik.touched.reason && (
            <Box className="text-red-500 text-sm mt-1">
              {formik.errors.reason}
            </Box>
          )}
        </FormControl>
        <div className="flex justify-end mt-6 gap-3">
          <Button
            variant="contained"
            className="m-auto white-btn"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit" className="m-auto px-12">
            Report
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ModalReport;
