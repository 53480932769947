import React, { useRef, useCallback, useEffect, memo } from 'react';
import VideoPlayer from '../VideoPlayer';

const VideoPlayerWithTracking = memo(({ src, onDurationChange, handleWatchTimeUpdated }) => {
  const videoTimeRef = useRef(0);
  const lastUpdateTimeRef = useRef(0);
  const isPlayingRef = useRef(false);
  const timeUpdateCallbackRef = useRef(handleWatchTimeUpdated);

  useEffect(() => {
    timeUpdateCallbackRef.current = handleWatchTimeUpdated;
  }, [handleWatchTimeUpdated]);

  const handleTimeUpdate = useCallback((currentVideoTime) => {
    if (!isPlayingRef.current) return;

    videoTimeRef.current = currentVideoTime;
    const timeDiff = currentVideoTime - lastUpdateTimeRef.current;
    
    if (timeDiff >= 5) {
      timeUpdateCallbackRef.current(timeDiff);
      lastUpdateTimeRef.current = currentVideoTime;
    }
  }, []);

  const handleFinalUpdate = useCallback(() => {
    const timeDiff = videoTimeRef.current - lastUpdateTimeRef.current;
    if (timeDiff > 0) {
      timeUpdateCallbackRef.current(timeDiff);
      lastUpdateTimeRef.current = videoTimeRef.current;
    }
  }, []);

  const handlePlay = useCallback(() => {
    isPlayingRef.current = true;
    lastUpdateTimeRef.current = videoTimeRef.current;
  }, []);

  const handlePause = useCallback(() => {
    isPlayingRef.current = false;
    handleFinalUpdate();
  }, [handleFinalUpdate]);

  const handleEnded = useCallback(() => {
    isPlayingRef.current = false;
    handleFinalUpdate();
  }, [handleFinalUpdate]);

  const handleVideoTimeUpdate = useCallback((event) => {
    const video = event.target;
    const currentTime = Math.floor(video.currentTime);
    handleTimeUpdate(currentTime);
  }, [handleTimeUpdate]);

  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (!videoElement) return;

    videoElement.addEventListener('timeupdate', handleVideoTimeUpdate);

    return () => {
      handleFinalUpdate();
      videoElement.removeEventListener('timeupdate', handleVideoTimeUpdate);
    };
  }, [handleVideoTimeUpdate, handleFinalUpdate]);

  return (
    <VideoPlayer
      src={src}
      onDurationChange={onDurationChange}
      onPlay={handlePlay}
      onPause={handlePause}
      onEnded={handleEnded}
    />
  );
});

export default VideoPlayerWithTracking;
