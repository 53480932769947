import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/language";

export const getAllLanguages = async () => {
  const URL = `${URI}/getLanguage`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const getAllLanguagesWeb = async () => {
  const URL = `${URI}/getLanguage`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const addLanguage = async (payload) => {
  const URL = `${URI}/addLanguage`;
  let formData = new FormData();
  formData.append("language", payload.language); //append the values with key, value pair
  formData.append("file", payload.file);
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: formData,
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const updateLanguage = async (payload) => {
  const URL = `${URI}/editLanguage`;
  let formData = new FormData();
  formData.append("language", payload.language); //append the values with key, value pair
  formData.append("file", payload.file);
  formData.append("id", payload.id);
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const deleteLanguage = async (payload) => {
  const URL = `${URI}/deleteLanguage`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { id: payload.id, ...data };
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};
