import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import PushNotification from "./../../../../../assets/images/push-notification.svg";
import SiteNotification from "./../../../../../assets/images/site-notification.svg";
import ToastNotification from "./../../../../../assets/images/toast-notification.svg";
// import Telegram from "./../../../../../assets/images/telegram.svg";
import ProfileMail from "./../../../../../assets/images/profile-email.svg";
import { useDispatch } from "react-redux";
import { ProfileButtons, ProfileSectionWrapper } from "../../../../atoms";
import {
  ModalEmailNotifications,
  ModalPushNotifications,
  ModalSiteNotifications,
  ModalTelegramBot,
  ModalToastNotifications,
} from "../../../../molecules";
import { openModal } from "../../../../../store/slices/common/modal";
import InfoIcon from "@mui/icons-material/Info";
import { usePermission } from "@custom-react-hooks/use-permission";

const ProfileNotifications = () => {
  // Notifications Tab in My Profile Section
  const dispatch = useDispatch();

  const handleOpenDialog = (name) => {
    if (name === "push-notification") {
      dispatch(
        openModal({
          title: "PUSH NOTIFICATIONS",
          content: <ModalPushNotifications />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "email-notification") {
      dispatch(
        openModal({
          title: "EMAIL NOTIFICATIONS",
          content: <ModalEmailNotifications />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "site-notification") {
      dispatch(
        openModal({
          title: "SITE NOTIFICATIONS",
          content: <ModalSiteNotifications />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "toast-notification") {
      dispatch(
        openModal({
          title: "TOAST NOTIFICATIONS",
          content: <ModalToastNotifications />,
          hasCloseBtn: true,
        })
      );
    }
    if (name === "telegram-bot") {
      dispatch(
        openModal({
          title: "TELEGRAM BOT",
          content: <ModalTelegramBot />,
          hasCloseBtn: true,
        })
      );
    }
  };
  const { state: browserNotificationStatus } = usePermission("notifications");

  return (
    <Grid container spacing={2} className="fans-profile mt-3">
      <Grid item xs={12} md={12} lg={12} className="mb-4">
        <div className="bg-white rounded-2xl py-3 px-5 h-calc-200">
          <Box display="flex" alignItems="center" mb={1}>
            <Typography
              variant="h3"
              className="font-semibold text-darkblue font-24"
            >
              Notifications
            </Typography>
            <Tooltip
              title={
                browserNotificationStatus === "granted"
                  ? "Notifications are enabled in your browser."
                  : browserNotificationStatus === "denied"
                  ? "Notifications are blocked. Enable them in browser settings."
                  : "Notifications are not enabled. Click to allow notifications."
              }
              arrow
            >
              <InfoIcon
                color={
                  browserNotificationStatus === "granted" ? "success" : "error"
                }
                style={{ marginLeft: "8px", cursor: "pointer" }}
              />
            </Tooltip>
          </Box>
          {/* Error message below the header */}
          {browserNotificationStatus !== "granted" && (
            <Box mb={2}>
              <Typography color="error" variant="body2" mb={1}>
                Notifications are not enabled. Please allow them in the browser
                tab settings.
              </Typography>
            </Box>
          )}
          <ProfileSectionWrapper title={"Additional Info"}>
            <Grid container spacing={2} className="fans-profile account-info">
              <ProfileButtons
                endAdornment={
                  <img
                    src={PushNotification}
                    alt="PushNotification"
                    onClick={() => handleOpenDialog("push-notification")}
                  />
                }
                title={"Push Notifications"}
                hasOneValue={false}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={ProfileMail}
                    alt="Profile Mail"
                    onClick={() => handleOpenDialog("email-notification")}
                  />
                }
                title={"Email Notifications"}
                hasOneValue={false}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={SiteNotification}
                    alt="SiteNotification"
                    onClick={() => handleOpenDialog("site-notification")}
                  />
                }
                title={"Site Notifications"}
                hasOneValue={false}
              />
              <ProfileButtons
                endAdornment={
                  <img
                    src={ToastNotification}
                    alt="ToastNotification"
                    onClick={() => handleOpenDialog("toast-notification")}
                  />
                }
                title={"Toast Notifications"}
                hasOneValue={false}
              />
            </Grid>
          </ProfileSectionWrapper>

          {/* <ProfileSectionWrapper title={"Other"}>
            <Grid container spacing={2} className='fans-profile account-info'>
              <ProfileButtons endAdornment={<img src={Telegram} alt='Telegram' onClick={() => handleOpenDialog("telegram-bot")} />} title={"Telegram Bot"} hasOneValue={false} />
            </Grid>
          </ProfileSectionWrapper> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default ProfileNotifications;
