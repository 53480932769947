import axiosClient from "../../../../utils/api";

// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/documentType";

export const getAllDocTypes = async () => {
  const URL = `${URI}/get`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const addDocType = async (payload) => {
  const URL = `${URI}/add`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const updateDocType = async (payload) => {
  const URL = `${URI}/update`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const deleteDocType = async (payload) => {
  const URL = `${URI}/delete`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    let { data } = response;
    return { id: payload.id, ...data };
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};
