import React from "react";
import MessagesIcon from "../../../../../../../assets/SVG/SideBar/MessagesIcon";
import { IconButton } from "@mui/material";
import { getColor } from "../../../../../../../utils/colors";

const ChatIconButton = ({ size = 28 }) => {
  const handleChatReddircet = () => {
  };
  return (
    <div className="flex items-center gap-[12px]">
      <IconButton
        aria-label="delete"
        sx={{ padding: "0px !important" }}
        onClick={() => {
          handleChatReddircet();
        }}
      >
        <MessagesIcon size={size} color={getColor("primary")} />
      </IconButton>
    </div>
  );
};

export default ChatIconButton;
