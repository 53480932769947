import React, { useEffect } from "react";
import { HomeStories } from "../../../../atoms";
import StoryCreator from "../StoryModal/StoryCreator";
import { useDispatch, useSelector } from "react-redux";
import { getStoriesAction } from "../../../../../store/middlewares/web/post/posts";

const HomeStoriesSection = ({ isCreator = false }) => {
  const dispatch = useDispatch();
  const { stories } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getStoriesAction());
  }, [dispatch]);

  return (
    <div className="flex overflow-x-auto gap-4 profile-images hidden-scrollbar">
      {isCreator && <StoryCreator />}
      {stories?.map((creatorStories) => {
        const firstStory = creatorStories.stories[0];
        const hasUnviewedStories = creatorStories.stories.some(story => !story.hasViewed);
        
        return (
          <HomeStories
            key={creatorStories.id}
            story={{
              id: firstStory.id,
              postMedia: firstStory.postMedia,
              users: creatorStories.user,
              hasViewed: !hasUnviewedStories,
              createdAt: firstStory.createdAt,
              hasLiked: firstStory.hasLiked,
              likesCount: firstStory.likesCount,
              allStories: creatorStories.stories
            }}
          />
        );
      })}
    </div>
  );
};

export default HomeStoriesSection;
