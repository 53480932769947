import axiosClient from "../../../../utils/api";

//Bookmarks
//Get Folders
export const getFolders = async () => {
  try {
    const response = await axiosClient.request({
      url: "/post/folderDetails",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Folders Service", error);
    throw error;
  }
};

//Create Folders
export const createFolders = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/createFolder",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Create Folders Service", error);
    throw error;
  }
};

//Delete Folders
export const deleteFolders = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/post/deleteFolder",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Delete Folders Service", error);
    throw error;
  }
};

//User Lists
//Get Folders
export const getUserListFolders = async () => {
  try {
    const response = await axiosClient.request({
      url: "/userListFolder/details",
      method: "post",
    });
    return response.data;
  } catch (error) {
    console.log("Error at Get Folders Service", error);
    throw error;
  }
};

//Create Folders
export const createUserListFolders = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/userListFolder/add",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Create Folders Service", error);
    throw error;
  }
};

//Delete Folders
export const deleteUserListFolders = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/userListFolder/delete",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at Delete Folders Service", error);
    throw error;
  }
};
