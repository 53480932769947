import { createSlice } from "@reduxjs/toolkit";
import {
  addComentsToPost,
  addPost,
  addToBookMarks,
  AnswerPollAction,
  AnswerQuizAction,
  BanPostAdmin,
  DeletePost,
  DeletePostAdmin,
  GetAllPostAdminAction, //?
  GetDeletedPostAdminAction,
  getFolderWisePostsAction,
  getPostsAction,
  getPostsByUserIdAdmin,
  getPostsByUserIdWebsiteAction,
  getPostsCollectionForUserListAction,
  getPostWebsiteAction,
  hidePostsOfAUserAction,
  likeDislikePost,
  removeCommentsAction,
  RestrictUserFromPostAction,
  getStoriesAction,
  getPostsWithCursorAction,
  getPostCommentsAction,
  updatePost,
  removeFromBookmarks,
  likeStoryPostMediaAction,
} from "../../../middlewares/web/post/posts";
import { GetAllReportedPostsAction } from "../../../middlewares/admin/cms/reportReasons";
import _ from "lodash";

const pendingPostsState = (state) => {
  state.postsLoading = true;
  state.postsError = false;
  state.postsSuccess = false;
  state.postsMessage = "";
  state.nextPostsCursor = null;
  state.isInitialPostsLoaded = true;
};

const fulfilledPostsState = (state, action) => {
  const tmp = action?.payload?.response?.posts || [];
  state.posts = _.uniqBy([...state.posts, ...tmp], "id");
  state.nextPostsCursor = action?.payload?.response?.nextCursor || null;
  state.postsLoading = false;
  state.postsSuccess = true;
  state.postsError = false;
  state.postsMessage = action?.payload?.message || "";
  state.postsHasNextPage = action?.payload?.response?.hasNextPage;
};

export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    postsLoading: false,
    postsMessage: "",
    postsError: false,
    postsSuccess: false,
    posts: [],
    nextPostsCursor: undefined,
    addPostsMessage: "",
    addPostsError: false,
    addPostsSuccess: false,
    updatePostsMessage: "",
    updatePostsError: false,
    updatePostsSuccess: false,
    isInitialPostsLoaded: false,
    postsHasNextPage: true,

    storiesLoading: false,
    storiesError: false,
    storiesSuccess: false,
    stories: [],
    storiesMessage: "",

    //used for /user/post/
    commentsLoading: false,
    nextCommentsCursor: undefined,
    commentsHasNextPage: true,
    isInitialCommentsLoaded: false,

    redirectTo: null,
  },
  reducers: {
    userAddToUserList: (state, action) => {
      const { id } = action.payload;
      let tempArray = state.posts?.map((post) => {
        if (post.userId === id) {
          return { ...post, isAddedInUserList: !post.isAddedInUserList };
        }
        return { ...post };
      });
      state.posts = tempArray;
    },
    blockUserFromPost: (state, action) => {
      const { id } = action.payload;
      let tempArray = state.posts?.map((post) => {
        if (post.userId === id) {
          return { ...post, blocked: !post.blocked };
        }
        return { ...post };
      });
      state.posts = tempArray;
    },
    subscribeToUser: (state, action) => {
      const { creatorId } = action.payload;
      let tempArray = state.posts?.map((post) => {
        if (post.users?.id === creatorId) {
          return { ...post, isSubscribed: !post.isSubscribed };
        }
        return { ...post };
      });
      state.posts = tempArray;
    },
    addPostsReset: (state) => {
      state.addPostsMessage = "";
      state.addPostsError = false;
      state.addPostsSuccess = false;
    },
    removePostsFromBookmark: (state, action) => {
      let { folderId } = action.payload;
      let tempArray = state.posts.filter(
        (post) => post.bookmark_post[0].folderId !== folderId
      );
      state.posts = tempArray;
    },
    resetPosts: (state) => {
      state.posts = [];
      state.nextPostsCursor = undefined;
      state.isInitialPostsLoaded = false;
      state.stories = [];
      state.nextCommentsCursor = undefined;
    },
    clearRedirect: (state) => {
      state.redirectTo = null;
    },
    removePost: (state, action) => {
      const { postId } = action.payload;
      state.posts = state.posts.filter((post) => post.id !== postId);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPostsAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostsAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      // get Stories
      .addCase(getStoriesAction.pending, (state) => {
        state.storiesLoading = true;
        state.storiesError = false;
        state.storiesSuccess = false;
        state.storiesMessage = "";
        state.stories = [];
      })
      .addCase(getStoriesAction.fulfilled, (state, action) => {
        state.storiesLoading = false;
        state.storiesError = false;
        state.storiesSuccess = true;
        
        const storiesByCreator = action.payload.response.reduce((acc, story) => {
          const creatorId = story.userId;
          if (!acc[creatorId]) {
            acc[creatorId] = {
              id: creatorId,
              user: story.users,
              stories: []
            };
          }
          acc[creatorId].stories.push({
            id: story.id,
            createdAt: story.createdAt,
            hasViewed: story.hasViewed,
            likesCount: story.likesCount,
            hasLiked: story.hasLiked,
            postMedia: story.postMedia
          });
          return acc;
        }, {});

        state.stories = Object.values(storiesByCreator);
        state.storiesMessage = action.payload.message;
      })
      .addCase(getStoriesAction.rejected, (state, action) => {
        state.storiesLoading = false;
        state.storiesError = true;
        state.storiesSuccess = false;
        state.stories = [];
        state.storiesMessage = action.payload.message;
      })
      //get Post By Id In Website
      .addCase(getPostWebsiteAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostWebsiteAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = [action.payload.response];
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostWebsiteAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get posts By user Id In Website
      .addCase(getPostsByUserIdWebsiteAction.pending, (state) => {
        pendingPostsState(state);
      })
      .addCase(getPostsByUserIdWebsiteAction.fulfilled, (state, action) => {
        fulfilledPostsState(state, action);
      })
      .addCase(getPostsByUserIdWebsiteAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get posts By user Id In Website
      .addCase(getPostsByUserIdAdmin.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(getPostsByUserIdAdmin.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(getPostsByUserIdAdmin.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get Reported Posts
      .addCase(GetAllReportedPostsAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(GetAllReportedPostsAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(GetAllReportedPostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //get All Posts For Home Page
      //get Folder Wise Posts For Bookmarks Page
      .addCase(getFolderWisePostsAction.pending, (state) => {
        pendingPostsState(state)
      })
      .addCase(getFolderWisePostsAction.fulfilled, (state, action) => {
        fulfilledPostsState(state, action);
      })
      .addCase(getFolderWisePostsAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get All Posts Admin Side
      .addCase(GetAllPostAdminAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(GetAllPostAdminAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload?.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(GetAllPostAdminAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get Deleted Posts Admin Side
      .addCase(GetDeletedPostAdminAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(GetDeletedPostAdminAction.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.postsError = false;
        state.postsSuccess = true;
        state.posts = action.payload?.response;
        state.postsMessage = action.payload.message;
      })
      .addCase(GetDeletedPostAdminAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.postsMessage = action.payload.message;
      })
      //Get User List Collection Posts Admin Side
      .addCase(getPostsCollectionForUserListAction.pending, (state) => {
        state.postsLoading = true;
        state.postsError = false;
        state.postsSuccess = false;
        state.postsMessage = "";
        state.posts = [];
      })
      .addCase(
        getPostsCollectionForUserListAction.fulfilled,
        (state, action) => {
          state.postsLoading = false;
          state.postsError = false;
          state.postsSuccess = true;
          state.posts = action.payload?.response;
          state.postsMessage = action.payload.message;
        }
      )
      .addCase(
        getPostsCollectionForUserListAction.rejected,
        (state, action) => {
          state.postsLoading = false;
          state.postsError = true;
          state.postsSuccess = false;
          state.posts = [];
          state.postsMessage = action.payload.message;
        }
      )
      //Delete Posts Admin Side
      .addCase(DeletePostAdmin.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(DeletePostAdmin.fulfilled, (state, action) => {
        let tempArray = state.posts.filter(
          (post) => post.id !== action.payload.postId
        );
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(DeletePostAdmin.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Delete Post In Admin
      .addCase(DeletePost.fulfilled, (state, action) => {
        let tempArray = state.posts.filter(
          (post) => post.id !== action.payload.postId
        );
        state.posts = tempArray;
      })
      //Restrict Posts
      .addCase(RestrictUserFromPostAction.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(RestrictUserFromPostAction.fulfilled, (state, action) => {
        let tempArray = state?.posts?.map((post) => {
          // if (post.users.id === action.payload.id) {
          //   return { ...post, restricted: !post.restricted };
          // }
          return { ...post };
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(RestrictUserFromPostAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Delete Posts Admin Side
      .addCase(hidePostsOfAUserAction.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(hidePostsOfAUserAction.fulfilled, (state, action) => {
        if (action.payload.type !== "unhide") {
          // let tempArray = state.posts.filter(
          //   (post) => post.users.id !== action.payload.id
          // );
          state.updatePostsError = false;
          state.updatePostsSuccess = true;
          // state.posts = tempArray;
          state.updatePostsMessage = action.payload.message;
        }
      })
      .addCase(hidePostsOfAUserAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Ban Posts Admin Side
      .addCase(BanPostAdmin.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(BanPostAdmin.fulfilled, (state, action) => {
        let tempArray = state.posts.map((post) => {
          if (post.id !== action.payload.postId) {
            return { ...post, bannedAt: new Date().toISOString() };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(BanPostAdmin.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      //Add Posts
      .addCase(addPost.pending, (state) => {
        state.addPostsError = false;
        state.addPostsSuccess = false;
        state.addPostsMessage = "";
      })
      .addCase(addPost.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { response, message },
        } = action;
        let tempArray = [response, ...posts];
        state.addPostsError = false;
        state.addPostsSuccess = true;
        state.posts = tempArray;
        state.addPostsMessage = message;
        window.location = "/home";
      })
      .addCase(addPost.rejected, (state, action) => {
        state.addPostsError = true;
        state.addPostsSuccess = false;
        state.addPostsMessage = action.payload.message;
      })

      //Like Dislike Posts
      .addCase(likeDislikePost.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(likeDislikePost.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { post_like, message, postId },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            return { ...post, post_like };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(likeDislikePost.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })

      //Add Comments
      .addCase(addComentsToPost.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(addComentsToPost.fulfilled, (state, action) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
        const index = state.posts.findIndex(
          (post) => post.id === action.payload.response.postId
        );
        state.posts[index].comments.unshift(action.payload.response.comment);
      })
      .addCase(addComentsToPost.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })

      //Remove Comments
      .addCase(removeCommentsAction.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(removeCommentsAction.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: { postId, message, commentId },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            let tempArray = post?.post_comment?.filter(
              (comment) => comment?.id !== commentId
            );
            return { ...post, post_comment: tempArray };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(removeCommentsAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(addToBookMarks.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(addToBookMarks.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { postId, id, isActive, folderId, bookmarkFolder, ...rest },
            message,
          },
        } = action;
        let tempArray = posts.map((post) => {
          if (post.id === postId) {
            return {
              ...post,
              bookmarkPost: {
                id,
                isActive,
                bookmarkFolder: {
                  id: folderId,
                  folderName: bookmarkFolder?.folderName
                }
              }
            };
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(addToBookMarks.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(removeFromBookmarks.pending, (state) => {
        state.updatePostsError = false;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = "";
      })
      .addCase(removeFromBookmarks.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { bookmarkId },
            message,
          },
        } = action;
        
        let tempArray = posts.map((post) => {
          if (post.bookmarkPost?.id === bookmarkId) {
            return {
              ...post,
              bookmarkPost: null
            };
          }
          return post;
        });
        
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(removeFromBookmarks.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(AnswerPollAction.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { postId, pollOptionId, ...rest },
            message,
          },
        } = action;
        let tempArray = posts.map((post) => {
          if (action.payload.response.id === post.id) {
            post = action.payload.response;
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(AnswerPollAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(AnswerQuizAction.fulfilled, (state, action) => {
        let { posts } = state;
        let {
          payload: {
            response: { postId, quizOptionId, ...rest },
            message,
          },
        } = action;

        let tempArray = posts.map((post) => {
          if (action.payload.response.id === post.id) {
            post = action.payload.response;
          }
          return post;
        });
        state.updatePostsError = false;
        state.updatePostsSuccess = true;
        state.posts = tempArray;
        state.updatePostsMessage = message;
      })
      .addCase(AnswerQuizAction.rejected, (state, action) => {
        state.updatePostsError = true;
        state.updatePostsSuccess = false;
        state.updatePostsMessage = action.payload.message;
      })
      .addCase(getPostsWithCursorAction.pending, (state) => {
        pendingPostsState(state);
      })
      .addCase(getPostsWithCursorAction.fulfilled, (state, action) => {
        fulfilledPostsState(state, action);
      })
      .addCase(getPostsWithCursorAction.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = true;
        state.postsSuccess = false;
        state.posts = [];
        state.nextPostsCursor = null;
      })

      .addCase(getPostCommentsAction.pending, (state) => {
        state.commentsLoading = true;
        state.isInitialCommentsLoaded = true;
      })
      .addCase(getPostCommentsAction.fulfilled, (state, action) => {
        state.commentsLoading = false;

        if (!state.posts[0]) {
          return;
        }
        const tmp = action?.payload?.response.comments || [];
        state.posts[0].comments = _.uniqBy(
          [...state.posts[0].comments, ...tmp],
          "id"
        );

        state.commentsMessage = action?.payload?.message || "";
        state.nextCommentsCursor =
          action?.payload?.response?.nextCursor || null;
        state.commentsHasNextPage = !!action?.payload?.response?.hasNextPage;
      })
      .addCase(getPostCommentsAction.rejected, (state, action) => {
        state.commentsLoading = false;
      })

      .addCase(updatePost.pending, (state) => {
        state.postsLoading = true;
        state.redirectTo = null;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.redirectTo = "/home";
      })
      .addCase(updatePost.rejected, (state) => {
        state.postsLoading = false;
        state.redirectTo = null;
      })
      .addCase(likeStoryPostMediaAction.fulfilled, (state, action) => {
        const { postMediaId, isActive } = action.payload.response;
 
        state.stories = state.stories.map(creatorStories => {
          // Update stories array for this creator
          const updatedStories = creatorStories.stories.map(story => {
            const updatedPostMedia = story.postMedia.map(media => {
              if (media.id === postMediaId) {
                return {
                  ...media,
                  hasLiked: isActive
                };
              }
              return media;
            });
            
            const hasAnyLiked = updatedPostMedia.some(media => media.hasLiked);
            
            return {
              ...story,
              postMedia: updatedPostMedia,
              hasLiked: hasAnyLiked,
              likesCount: hasAnyLiked ? story.likesCount + (isActive ? 1 : -1) : story.likesCount
            };
          });

          return {
            ...creatorStories,
            stories: updatedStories
          };
        });
      });
  },
});

export const {
  userAddToUserList,
  blockUserFromPost,
  subscribeToUser,
  addPostsReset,
  removePostsFromBookmark,
  resetPosts,
  clearRedirect,
  removePost,
} = postsSlice.actions;

export default postsSlice.reducer;
