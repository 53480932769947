// Mock data for messages
const mockMessages = [
  {
    id: 1,
    senderId: 1,
    lastMessage: "Hey, how's it going?",
    unreadCount: 2,
    timestamp: new Date(Date.now() - 1000 * 60 * 5).toISOString(), // 5 minutes ago
    name: "John Doe",
    avatar: "https://i.pravatar.cc/150?img=1",
    isOnline: true
  },
  {
    id: 2,
    senderId: 2,
    lastMessage: "Did you see the latest updates?",
    unreadCount: 0,
    timestamp: new Date(Date.now() - 1000 * 60 * 30).toISOString(), // 30 minutes ago
    name: "Alice Smith",
    avatar: "https://i.pravatar.cc/150?img=5",
    isOnline: false
  },
  {
    id: 3,
    senderId: 3,
    lastMessage: "Let's catch up soon!",
    unreadCount: 1,
    timestamp: new Date(Date.now() - 1000 * 60 * 60).toISOString(), // 1 hour ago
    name: "Bob Johnson",
    avatar: "https://i.pravatar.cc/150?img=3",
    isOnline: true
  }
];

const mockThreads = {
  1: {
    id: 1,
    messages: [
      { id: 1, senderId: 1, text: "Hey, how's it going?", timestamp: new Date(Date.now() - 1000 * 60 * 60).toISOString() },
      { id: 2, senderId: "me", text: "Hi! I'm doing well, thanks!", timestamp: new Date(Date.now() - 1000 * 60 * 55).toISOString() },
      { id: 3, senderId: 1, text: "Great to hear! Have you been working on any interesting projects?", timestamp: new Date(Date.now() - 1000 * 60 * 50).toISOString() },
      { id: 4, senderId: "me", text: "Yes, actually! I've been building a chat application.", timestamp: new Date(Date.now() - 1000 * 60 * 45).toISOString() }
    ]
  },
  2: {
    id: 2,
    messages: [
      { id: 5, senderId: 2, text: "Did you see the latest updates?", timestamp: new Date(Date.now() - 1000 * 60 * 40).toISOString() },
      { id: 6, senderId: "me", text: "Not yet, what's new?", timestamp: new Date(Date.now() - 1000 * 60 * 35).toISOString() },
      { id: 7, senderId: 2, text: "They added some really cool features!", timestamp: new Date(Date.now() - 1000 * 60 * 30).toISOString() }
    ]
  },
  3: {
    id: 3,
    messages: [
      { id: 8, senderId: 3, text: "Let's catch up soon!", timestamp: new Date(Date.now() - 1000 * 60 * 25).toISOString() },
      { id: 9, senderId: "me", text: "Definitely! How about next week?", timestamp: new Date(Date.now() - 1000 * 60 * 20).toISOString() },
      { id: 10, senderId: 3, text: "Sounds good to me!", timestamp: new Date(Date.now() - 1000 * 60 * 15).toISOString() }
    ]
  }
};

export const getMessages = async () => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  return mockMessages;
};

export const getMessageThread = async (threadId) => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  return mockThreads[threadId] || { id: threadId, messages: [] };
};

export const sendMessage = async (data) => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  const newMessage = {
    id: Date.now(),
    senderId: "me",
    text: data.text,
    timestamp: new Date().toISOString()
  };
  return newMessage;
};

export const deleteMessage = async () => {
  // Simulate API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  return { success: true };
}; 