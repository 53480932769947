import React from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "../../../../../atoms/InfiniteScroll";

const LoadMorePosts = ({ onLoadMore, loading }) => {
  const { isInitialPostsLoaded, posts } = useSelector((state) => state.posts);
  return (
    <InfiniteScroll
      onLoadMore={onLoadMore}
      loading={loading}
      isInitialDataLoaded={isInitialPostsLoaded}
      dataLength={posts.length}
    />
  );
};

export default LoadMorePosts;
