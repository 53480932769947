import React from "react";

const AllIcon = ({ white = false }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 13V7.99996C18.3332 3.83329 16.6665 2.16663 12.4998 2.16663H7.49984C3.33317 2.16663 1.6665 3.83329 1.6665 7.99996V13C1.6665 17.1666 3.33317 18.8333 7.49984 18.8333H12.4998C16.6665 18.8333 18.3332 17.1666 18.3332 13Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01666 14.9167C8.99237 14.9167 9.78333 14.1258 9.78333 13.1501C9.78333 12.1744 8.99237 11.3834 8.01666 11.3834C7.04096 11.3834 6.25 12.1744 6.25 13.1501C6.25 14.1258 7.04096 14.9167 8.01666 14.9167Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.78369 13.1501V6.9751"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.942 6.14175L12.892 6.79172C13.367 6.95005 13.7504 7.48338 13.7504 7.98338V8.50004C13.7504 9.17504 13.2254 9.55004 12.592 9.33338L10.642 8.6834C10.167 8.52507 9.78369 7.99174 9.78369 7.49174V6.97508C9.78369 6.30841 10.3004 5.92508 10.942 6.14175Z"
        stroke={white ? "#FFF" : "#2799F6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AllIcon;
