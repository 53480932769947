import { createSlice } from "@reduxjs/toolkit";
import {
  BanUserAction,
  DeleteUserAction,
  GetActiveUsersAction,
  GetBannedUsersAction,
  GetBlockedUsersAction,
  GetRestrictedUsersAction,
  getUsersCollectionForUserListAction,
  upsertUserToUserListAction,
} from "../../../middlewares/admin/users/users";
import {
  GetActiveCreatorsAction,
  GetBannedCreatorAction,
  GetPendingCreatorsAction,
  UpdatePendingCreatorStatusAction,
} from "../../../middlewares/admin/users/creators";
import { GetAllReportedUsersAction } from "../../../middlewares/admin/cms/reportReasons";

const initialState = {
  getUsersLoading: false,
  users: [],
  getUsersError: false,
  getUsersMessage: "",
  banUserSuccess: false,
  banUserError: false,
  banUserMessage: "",
  deleteUserSuccess: false,
  deleteUserError: false,
  deleteUserMessage: "",
  hasNextPage: false,
  nextCursor: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    deletedUsersRequest: (state) => {
      state.getUsersLoading = true;
      state.getUsersError = false;
      state.getUsersMessage = "";
      state.banUserSuccess = false;
      state.banUserError = false;
      state.banUserMessage = "";
      state.deleteUserSuccess = false;
      state.deleteUserError = false;
      state.deleteUserMessage = "";
    },
    deletedUsersSuccess: (state, action) => {
      state.getUsersLoading = false;
      state.getUsersError = false;
      state.users = action.payload.data;
      state.getUsersMessage = action.payload.message;
    },
    deletedUsersError: (state, action) => {
      state.getUsersLoading = false;
      state.getUsersError = true;
      state.getUsersMessage = action.payload.message;
      state.users = [];
    },
    resetUser: (state) => {
      state.getUsersLoading = false;
      state.deleteUserSuccess = false;
      state.deleteUserError = false;
      state.deleteUserMessage = "";
      state.banUserSuccess = false;
      state.banUserError = false;
      state.banUserMessage = "";
    },
    resetUsers: (state) => {
      state.users = [];
      state.nextCursor = null;
      state.hasNextPage = false;
      state.getUsersLoading = false;
      state.getUsersError = false;
      state.getUsersMessage = "";
    },
    RestrictUsersAction: (state, action) => {
      let { users } = state;
      let tempArray = users?.map((user) => {
        if(user?.id === action.payload.id) {
          return { ...user, restricted: !user?.restricted}
        } 
        return {...user}
      })
      state.users = tempArray
    },
    BlockedUsersAction: (state, action) => {
      let { users } = state;
      let tempArray = users?.map((user) => {
        if(user?.id === action.payload.id) {
          return { ...user, blocked: !user?.blocked}
        } 
        return {...user}
      })
      state.users = tempArray
    },
    setFilteredUsers: (state, action) => {
      const { users, nextCursor, hasNextPage, append } = action.payload;
      state.getUsersLoading = false;
      state.getUsersError = false;
      if (append) {
        state.users = [...state.users, ...users];
      } else {
        state.users = users;
      }
      state.nextCursor = nextCursor;
      state.hasNextPage = hasNextPage;
    },
    setUsersLoading: (state, action) => {
      state.getUsersLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetActiveUsersAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetActiveUsersAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response.users
          ? action.payload.response.users
          : action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetActiveUsersAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(GetBlockedUsersAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetBlockedUsersAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response.users
          ? action.payload.response.users
          : action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetBlockedUsersAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(GetRestrictedUsersAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetRestrictedUsersAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetRestrictedUsersAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(GetBannedUsersAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetBannedUsersAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response.users
          ? action.payload.response.users
          : action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetBannedUsersAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(GetActiveCreatorsAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetActiveCreatorsAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response.users
          ? action.payload.response.users
          : action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetActiveCreatorsAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(getUsersCollectionForUserListAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(
        getUsersCollectionForUserListAction.fulfilled,
        (state, action) => {
          state.getUsersLoading = false;
          state.getUsersError = false;
          state.users = action.payload.response.users;
          state.getUsersMessage = action.payload.response.message;
        }
      )
      .addCase(
        getUsersCollectionForUserListAction.rejected,
        (state, action) => {
          state.getUsersLoading = false;
          state.getUsersError = true;
          state.getUsersMessage = action.payload.message;
          state.users = [];
        }
      )
      .addCase(upsertUserToUserListAction.pending, (state) => {
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(upsertUserToUserListAction.fulfilled, (state, action) => {
        let tempUsers = state.users.filter(
          (user) => user.id !== action.payload.userId
        );
        state.users = tempUsers;
        state.deleteUserSuccess = true;
        state.deleteUserError = false;
        state.deleteUserMessage = action.payload.message;
      })
      .addCase(upsertUserToUserListAction.rejected, (state, action) => {
        state.deleteUserSuccess = false;
        state.deleteUserError = true;
        state.deleteUserMessage = action.payload.message;
      })
      .addCase(GetBannedCreatorAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetBannedCreatorAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response.users
          ? action.payload.response.users
          : action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetBannedCreatorAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(GetPendingCreatorsAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetPendingCreatorsAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response.users
          ? action.payload.response.users
          : action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetPendingCreatorsAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(UpdatePendingCreatorStatusAction.fulfilled, (state, action) => {
        let { id, status, reason } = action.payload;
        let { users } = state;
        let tempArray = [];
        if (status === "accepted") {
          tempArray = users?.filter((user) => user.id !== id);
        }
        if (status === "rejected") {
          tempArray = users?.map((user) => {
            if (user?.id === id) {
              return { ...user, reason, requestStatus: status };
            }
            return { ...user };
          });
        }
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = tempArray;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetAllReportedUsersAction.pending, (state) => {
        state.getUsersLoading = true;
        state.users = [];
        state.getUsersError = false;
        state.getUsersMessage = "";
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(GetAllReportedUsersAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = false;
        state.users = action.payload.response;
        state.getUsersMessage = action.payload.message;
      })
      .addCase(GetAllReportedUsersAction.rejected, (state, action) => {
        state.getUsersLoading = false;
        state.getUsersError = true;
        state.getUsersMessage = action.payload.message;
        state.users = [];
      })
      .addCase(BanUserAction.pending, (state) => {
        state.banUserSuccess = false;
        state.banUserError = false;
        state.banUserMessage = "";
      })
      .addCase(BanUserAction.fulfilled, (state, action) => {
        let tempUsers = state.users.map((user) => {
          if (user.id === action.payload.id) {
            if (user?.bannedAt) {
              return { ...user, bannedAt: null };
            } else {
              return { ...user, bannedAt: new Date().toISOString() };
            }
          } else {
            return user;
          }
        });
        state.users = tempUsers;
        state.banUserSuccess = true;
        state.banUserError = false;
        state.banUserMessage = action.payload.message;
      })
      .addCase(BanUserAction.rejected, (state, action) => {
        state.banUserSuccess = false;
        state.banUserError = true;
        state.banUserMessage = action.payload.message;
      })
      .addCase(DeleteUserAction.pending, (state) => {
        state.deleteUserSuccess = false;
        state.deleteUserError = false;
        state.deleteUserMessage = "";
      })
      .addCase(DeleteUserAction.fulfilled, (state, action) => {
        let tempUsers = state.users.filter(
          (user) => user.id !== action.payload.id
        );
        state.users = tempUsers;
        state.deleteUserSuccess = true;
        state.deleteUserError = false;
        state.deleteUserMessage = action.payload.message;
      })
      .addCase(DeleteUserAction.rejected, (state, action) => {
        state.deleteUserSuccess = false;
        state.deleteUserError = true;
        state.deleteUserMessage = action.payload.message;
      });
  },
});

export const { resetUser, resetUsers, RestrictUsersAction, BlockedUsersAction, setFilteredUsers, setUsersLoading } = usersSlice.actions;

export default usersSlice.reducer;
