import React, { useEffect, useState, useRef } from "react";
import { IconButton } from "@mui/material";
import UserBlank from "./../../../../../assets/images/blank.png";
import ArchiveTick from "./../../../../../assets/images/archive-tick.svg";
import formatDateForPost from "../../../../../utils/formatDateForPost";
import { useDispatch, useSelector } from "react-redux";
import {
  Postheader,
  ModalAddBookMark,
  DisplayQuizInPost,
  DisplayPollInPost,
  PostDropdown,
  UserPostDropdown,
} from "../../../../molecules";
import { DisplayImagesInPost, LockedPost } from "../../../../atoms";
import { removeFromBookmarks } from "../../../../../store/middlewares/web/post/posts";
import { openModal } from "../../../../../store/slices/common/modal";
import BookmarkBlueFill from "./../../../../../assets/images/bookmark-fill-blue.svg";
import { useNavigate } from "react-router-dom";
import LikePost from "../../../../atoms/LikePost";
import SendTipButton from "../../../../molecules/web/common/SendTipButton";
import PostCommentsList from "../posts/PostCommentsList";
import AudioPlayer from "../../../../molecules/web/common/media/AudioPlayer";
import { PinIcon } from "../../../../../assets/SVG/PinIcon";
import { getColor } from "../../../../../utils/colors";
import { createPostView } from "../../../../../store/services/web/post/posts.services";
import { postVideoWatchTime } from "../../../../../store/services/web/post/posts.services";
import VideoPlayerWithTracking from "../../../../molecules/web/common/media/VideoPlayerWithTracking";

const DisplayPost = ({
  post,
  rightToolbar = null,
  refresh = false,
  allowCommentsPagination = false,
  isPostPinned = false,
  isSchedulePreview = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [postImages, setPostImages] = useState([]);
  const [postAudio, setPostAudio] = useState([]);
  const [postVideo, setPostVideo] = useState(null);
  const postRef = useRef(null);

  const { me } = useSelector((state) => state.me);
  const isPostCreator = post?.user?.id === me?.id;

  const isProfileCompleted = Boolean(
    me?.coverImg && me?.profileImage && me?.bio
  );

  useEffect(() => {
    if (me) {
      setIsLoggedInUser(me?.id === post?.userId);
    }
    const validMedia = Array.isArray(post?.media) ? post.media : [];

    setPostImages(
      validMedia.filter((media) => media?.mediaType?.includes("image"))
    );
    setPostAudio(
      validMedia.filter((media) => media?.mediaType?.includes("audio"))
    );
    setPostVideo(
      validMedia.find((media) => media?.mediaType?.includes("video"))
    );
  }, [post, me]);

  useEffect(() => {
    if (post?.hasViewed) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            createPostView(post.id);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.8,
      }
    );

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [post.id]);

  const handleWatchTimeUpdated = (watchTime) => {
    const payload = {
      postMediaId: postVideo?.id,
      postId: post?.id,
      watchTime: watchTime,
    };
    postVideoWatchTime(payload);
  };

  return (
    <div
      ref={postRef}
      className={`bg-white rounded-2xl p-4 mt-3 ${
        !post.isSubscribed && "creator-home-module profile-post"
      }`}
      key={post?.id}
    >
      {isPostPinned && (
        <div className="flex flex-row items-start mt-2 mb-2">
          <PinIcon color={getColor("primary")} size={18} />
          <p className="text-primary font-medium font-14 ml-2">Pinned</p>
        </div>
      )}
      <div className="flex flex-wrap justify-between items-center">
        <Postheader
          img={
            post?.user?.profileImage
              ? // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_AWS_URL}${post?.user?.profileImage}`
              : UserBlank
          }
          name={post?.user?.name}
          uname={post?.user?.username}
          id={post?.user?.id}
          isVerified={false}
          isLoggedInUser={isLoggedInUser}
        />

        <div className="flex items-center gap-3">
          {post?.createdAt && (
            <p className="font-medium font-14 text-black">
              {formatDateForPost(post?.createdAt)}
            </p>
          )}

          {!isPostCreator && (
            <PostDropdown post={post} refresh={refresh} disabled={false} />
          )}

          {isPostCreator &&
            (isLoggedInUser ? (
              isSchedulePreview ? (
                <UserPostDropdown
                  post={post}
                  displayCopyLink={false}
                  displayPinToProfile={false}
                  displayArchivePost={false}
                  displayAddATag={false}
                  displayViewDetailedStatistics={false}
                />
              ) : (
                <UserPostDropdown
                  post={post}
                  displayPinToProfile={
                    post.id === me?.pinnedPostId || !me?.pinnedPostId
                  }
                  displayDeletePost={false}
                />
              )
            ) : rightToolbar ? (
              rightToolbar
            ) : (
              <PostDropdown
                post={post}
                refresh={refresh}
                disabled={!isProfileCompleted}
              />
            ))}
        </div>
      </div>

      {
        // post.isSubscribed || post?.user?.userType?.toLowerCase() === "user" ?
        <>
          <div
            className="mt-4 mb-4 cursor-pointer"
            onClick={() => navigate(`/${post.user.username}/status/${post.id}`)}
          >
            <p>{post.description}</p>
          </div>
          {post.isLocked && (
            <LockedPost postMedia={[...postImages, ...postAudio]} />
          )}
          {!post.isLocked && (
            <DisplayImagesInPost
              filesSrc={postImages?.map(
                (media) =>
                  // eslint-disable-next-line no-undef
                  `${process.env.REACT_APP_AWS_URL}${media.fileURL}`
              )}
            />
          )}

          {!post.isLocked &&
            postAudio?.length > 0 &&
            postAudio.map((audio) => (
              <AudioPlayer
                src={`${process.env.REACT_APP_AWS_URL}${audio.fileURL}`}
                key={audio?.id}
              />
            ))}

          {postVideo && (
            <VideoPlayerWithTracking
              src={postVideo?.fileURL}
              handleWatchTimeUpdated={handleWatchTimeUpdated}
            />
          )}
        </>
        //  : (
        //   <LockedPost postMedia={postMedia} />
        // )
      }
      {!post.isLocked && (
        <>
          {post?.pollOptions?.length > 0 && <DisplayPollInPost post={post} />}
          {post?.quizOptions?.length > 0 && (
            <DisplayQuizInPost
              options={post?.quizOptions}
              isLoggedInUser={isLoggedInUser}
              postId={post?.id}
              expiry={post?.expiry}
              quizAnswersCount={post?.quizAnswersCount || 0}
              isCreator={post?.user?.id === me?.id}
            />
          )}

          {!isSchedulePreview && (
            <div className="flex justify-between mt-3">
              <div className="flex gap-3">
                <LikePost
                  likesCount={post?.likes?.count}
                  hasLiked={post?.likes?.hasLiked}
                  isDisabled={post?.restricted} ///XXX YOU DO NOT HAVE THIS DATA! you are not fetching restricted!
                  postId={post?.id}
                  showCounter={true}
                />

                <SendTipButton
                  creator={{
                    id: post?.user?.id,
                    name: post?.user?.name,
                    username: post?.user?.username,
                    profileImage: post?.user?.profileImage,
                  }}
                />
              </div>
              <>
                {post?.bookmarkPost ? (
                  <>
                    {!post?.bookmarkPost?.isActive ? (
                      <IconButton
                        aria-label="create-bookmark"
                        onClick={() => {
                          dispatch(
                            openModal({
                              title: "CREATE NEW BOOKMARK",
                              content: <ModalAddBookMark postId={post?.id} />,
                              hasCloseBtn: true,
                            })
                          );
                        }}
                      >
                        <img
                          src={ArchiveTick}
                          alt="ArchiveTick"
                          className="img-dark-filter"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete-bookmark"
                        onClick={() => {
                          let reqObj = {
                            bookmarkId: post?.bookmarkPost?.id,
                          };
                          dispatch(removeFromBookmarks(reqObj));
                        }}
                      >
                        <img
                          src={BookmarkBlueFill}
                          alt="BookmarkBlueFill"
                          className="img-dark-filter"
                        />
                      </IconButton>
                    )}
                  </>
                ) : (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      dispatch(
                        openModal({
                          title: "CREATE NEW BOOKMARK",
                          content: <ModalAddBookMark postId={post?.id} />,
                          hasCloseBtn: true,
                        })
                      );
                    }}
                  >
                    <img
                      src={ArchiveTick}
                      alt="ArchiveTick"
                      className="img-dark-filter"
                    />
                  </IconButton>
                )}
              </>
            </div>
          )}

          {!isSchedulePreview && (
            <PostCommentsList
              comments={post?.comments || []}
              postId={post.id}
              postCreatorUserId={post.user.id}
              allowCommentsPagination={allowCommentsPagination}
            />
          )}
          {isSchedulePreview && (
            <div>
              <div className="w-full h-[1px] bg-gray-200 my-3"></div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DisplayPost;
