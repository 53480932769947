import axiosClient from "../../../../utils/api";
// eslint-disable-next-line no-undef
const URI = process.env.REACT_APP_API_URL + "/admin/cms";

export const editCms = async (payload) => {
  const URL = `${URI}/updateCms`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const getSingleCMS = async (payload) => {
  const URL = `${URI}/getCms`;
  try {
    const response = await axiosClient.request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};
