import axiosClient from "../../../../utils/api";

export const getPost = async (payload) => {
  try {
    const response = await axiosClient.request({
      url: "/admin/post/postDetail",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("Error at get Post Service", error);
    throw error;
  }
};
