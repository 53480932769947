import React from 'react';

const MuteButton = ({ width = 27, height = 27, color = "#ffffff", onClick }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 18 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M4.125 8.00033V11.0003C4.125 12.5003 4.875 13.2503 6.375 13.2503H7.4475C7.725 13.2503 8.0025 13.3328 8.2425 13.4753L10.4325 14.8478C12.3225 16.0328 13.875 15.1703 13.875 12.9428V6.05783C13.875 3.82283 12.3225 2.96783 10.4325 4.15283L8.2425 5.52533C8.0025 5.66783 7.725 5.75033 7.4475 5.75033H6.375C4.875 5.75033 4.125 6.50033 4.125 8.00033Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
    </svg>
  );
};

export default MuteButton;
