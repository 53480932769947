import React, { useState, useCallback, useEffect } from "react";
import { PageHeader } from "../../../../newComponents/atoms";
import { useTheme } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Button from "@mui/material/Button";
import {
  Grid,
  Box,
  Tab,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Tabs,
} from "@mui/material";
import vaultTabs from "../../../../utils/vaultTabs";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import MinusIcon from "@mui/icons-material/RemoveCircleOutline";
import listmenublack from "../../../../assets/images/listmenublack.svg";
import listmenuwhite from "../../../../assets/images/listmenuwhite.svg";
import MediaSearchResults from "../../../../newComponents/molecules/web/common/MediaSearchResults";
import VaultSortingMenu from "./components/VaultSortingMenu";
import { mockMediaItems } from "./mockMediaItems";
import VaultSearchHeader from "./components/VaultSearchHeader";
import VaultList from "./components/VaultList";
import { useDropzone } from "react-dropzone";
import vaultService from "../../../../store/services/web/vault/vault.service";
import { CircularProgress, Alert } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getVaultMediaAction } from "../../../../store/middlewares/web/vault/vault";
import {
  clearVaultMedia,
  toggleMediaSelection,
  addMediaToVault,
  setVaultFolders,
  setVaultFoldersLoading,
  setVaultFoldersError,
} from "../../../../store/slices/creator/vault";
import { openModal } from "../../../../store/slices/common/modal";
import ModalCreateFolder from "../../../../newComponents/molecules/web/vault/ModalCreateFolder";
import { getColor } from "../../../../utils/colors";
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import MediaItem from "../../../../newComponents/molecules/web/common/MediaItem";
import { snapCenterToCursor } from "@dnd-kit/modifiers";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
};

const tabToMediaType = {
  0: null,
  1: "image",
  2: "gif",
  3: "video",
  4: "audio",
};

export default function Vault({ isModal = false, onAddMedia }) {
  const [uploadMediaVisible, setUploadMediaVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [items, setItems] = useState(mockMediaItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadError, setUploadError] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState("all");

  const { darkMode } = useTheme();
  const dispatch = useDispatch();
  const {
    media,
    selectedMedia,
    folders,
  } = useSelector((state) => state.vault);

  const handleOpenUpload = () => {
    setUploadMediaVisible(true);
  };

  const handleCloseUpload = () => {
    setUploadMediaVisible(false);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectItem = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onUploadSuccess = useCallback(
    (response) => {
      setUploadMediaVisible(false);
      dispatch(addMediaToVault(response));
    },
    [dispatch]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsUploading(true);
      setUploadError(null);
      let hasError = false;

      for (const file of acceptedFiles) {
        try {
          const fileType = file.type;
          const mediaType = fileType.startsWith("video/")
            ? "video"
            : fileType.startsWith("audio/")
            ? "audio"
            : fileType === "image/gif"
            ? "gif"
            : fileType.startsWith("image/")
            ? "image"
            : null;

          if (!mediaType) {
            throw new Error("Unsupported file type");
          }

          const { preSignedUploadUrl, id } =
            (await vaultService.generateMediaUploadPreSignedUrl({
              fileType
            })).response;

          try {
            await axios.put(preSignedUploadUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress((prev) => ({
                  ...prev,
                  [file.name]: percentCompleted,
                }));
              },
            });

            const addToVaultResponse = await vaultService.addToVault({
              mediaId: id,
            });

            onUploadSuccess(addToVaultResponse);

            setUploadProgress((prev) => ({
              ...prev,
              [file.name]: 100,
            }));
          } catch (uploadError) {
            console.error("Error uploading file:", uploadError);
            hasError = true;
            throw new Error(`Failed to upload ${file.name}`);
          }
        } catch (error) {
          console.error("Upload error:", error);
          hasError = true;
          setUploadError(`Failed to upload ${file.name}: ${error.message}`);
        }
      }

      setIsUploading(false);

      if (!hasError) {
        setTimeout(() => {
          setUploadProgress({});
          setUploadMediaVisible(false);
        }, 1000);
      }
    },
    [onUploadSuccess]
  );

  const handleMediaSelect = useCallback(
    (mediaId) => {
      dispatch(toggleMediaSelection(mediaId));
    },
    [dispatch]
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    dispatch(clearVaultMedia());
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
      "video/*": [],
      "audio/*": [],
      "image/gif": [],
    },
  });

  // Transform media data
  const transformedMedia =
    media?.map((item) => ({
      id: item.vaultId,
      type: item.mediaType,
      thumbnail: item.fileURL,
      date: formatDate(item.createdAt),
      selected: selectedMedia.includes(item.vaultId),
    })) || [];

  const fetchVaultFolders = useCallback(async () => {
    dispatch(setVaultFoldersLoading());
    try {
      const response = await vaultService.getAllVaultFolders();
      dispatch(setVaultFolders(response?.response || []));
    } catch (error) {
      dispatch(setVaultFoldersError(error.message || "Failed to load folders"));
    }
  }, [dispatch]);

  useEffect(() => {
    const mediaType = tabToMediaType[selectedTab];
    dispatch(
      getVaultMediaAction({
        pageSize: 50,
        ...(selectedFolderId &&
          selectedFolderId !== "all" && { folderId: selectedFolderId }),
        ...(mediaType && { mediaType }),
      })
    );

    fetchVaultFolders();

    return () => dispatch(clearVaultMedia());
  }, [selectedFolderId, selectedTab]);

  const handleFolderSelect = (folderId) => {
    setSelectedFolderId(folderId);
  };

  const handleAddSelected = () => {
    if (onAddMedia && selectedMedia.length > 0) {
      const selectedFiles = transformedMedia.filter((item) =>
        selectedMedia.includes(item.id)
      );
      onAddMedia(selectedFiles);
    }
  };

  const selectedMediaFromRedux = useSelector(
    (state) => state.vault.selectedMedia
  );
  const [draggedMediaGroup, setDraggedMediaGroup] = useState([]);
  const isMultiDrag = draggedMediaGroup.length > 1;

  const handleDragStart = ({ active }) => {
    // Pronađi sve selektovane medije
    const allSelected = selectedMediaFromRedux.map((id) =>
      transformedMedia.find((item) => item.id === id)
    );

    // Ako nijedan nije selektovan (ili ne nadjes?), onda samo current active
    const dragged =
      allSelected.length > 0
        ? allSelected
        : [transformedMedia.find((item) => item.id === active.id)];

    setDraggedMediaGroup(dragged);
  };

  const handleDragEnd = ({ active, over }) => {
    setDraggedMediaGroup([]);
    if (over && draggedMediaGroup.length > 0) {
      // Extract all mediaIds from draggedMediaGroup
      const mediaIdsToAdd = draggedMediaGroup.map((item) => item.id);

      vaultService
        .addMediaToFolder({
          folderId: over.id,
          vaultMediaToAdd: mediaIdsToAdd,
        })
        .then(() => {
          console.log("Added items:", mediaIdsToAdd);
          // Reload vault folders to update counts
          fetchVaultFolders();
          // Also reload current folder's media if we're viewing a specific folder
          if (selectedFolderId) {
            const mediaType = tabToMediaType[selectedTab];
            dispatch(
              getVaultMediaAction({
                pageSize: 50,
                ...(selectedFolderId !== "all" && { folderId: selectedFolderId }),
                ...(mediaType && { mediaType }),
              })
            );
          }
        })
        .catch(console.error);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      await vaultService.deleteFolder(folderId);
      // Nakon uspešnog brisanja, ponovo pozovi fetchVaultFolders() ili
      // filtriraj obrisani folder iz localnog stanja ako ga čuvaš
      fetchVaultFolders();
      // Ako foldere držiš u store-u ili u nekom lokalnom state-u,
      // ažuriraj ga nakon brisanja da se folder ne prikazuje više.
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 25, // Mora se pomeriti 5px pre nego što počne drag
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 25,
      },
    })
  );

  return (
    <DndContext
      onDragEnd={handleDragEnd} // Isključujemo DND za mobilne
      onDragStart={handleDragStart}
      sensors={sensors} // Isključujemo senzore za mobilne
      modifiers={[snapCenterToCursor]}
    >
      <DragOverlay>
        {draggedMediaGroup.length > 1 ? (
          // Više medija: jednostavan box
          <div
            style={{
              width: 150,
              height: 50,
              background: "#2196F3",
              border: "1px solid #ccc",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#FFF",
              cursor: "grabbing",
            }}
          >
            Moving {draggedMediaGroup.length} items
          </div>
        ) : draggedMediaGroup.length === 1 ? (
          // Jedan medij: prikaži MediaItem
          <MediaItem
            id={draggedMediaGroup[0].id}
            selected={true}
            type={draggedMediaGroup[0].type}
            thumbnail={draggedMediaGroup[0].thumbnail}
            date={draggedMediaGroup[0].date}
            onSelect={() => {}}
          />
        ) : null}
      </DragOverlay>
      <Grid container className="h-full fans-profile">
        <Grid item sm={12} md={8}>
          <div>
            {!isModal && (
              <PageHeader
                title={`All Media${uploadMediaVisible ? " uploading..." : ""}`}
                rightToolbar={
                  <Box>
                    <Button
                      sx={{ width: 220 }}
                      type="submit"
                      variant="contained"
                      onClick={
                        !uploadMediaVisible
                          ? handleOpenUpload
                          : handleCloseUpload
                      }
                      startIcon={
                        !uploadMediaVisible ? <PlusIcon /> : <MinusIcon />
                      }
                    >
                      {!uploadMediaVisible
                        ? "Upload Media"
                        : "Cancel upload..."}
                    </Button>
                  </Box>
                }
              />
            )}

            {uploadMediaVisible && (
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  p: 3,
                  border: "2px dashed grey",
                  borderRadius: 2,
                  bgcolor: isDragActive ? "rgba(0,0,0,0.1)" : "transparent",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isUploading ? (
                  <Box>
                    <Typography>Uploading files...</Typography>
                    {Object.entries(uploadProgress).map(
                      ([fileName, progress]) => (
                        <Box key={fileName} sx={{ mt: 1 }}>
                          <Typography variant="body2">
                            {fileName}: {progress}%
                          </Typography>
                          <CircularProgress
                            variant="determinate"
                            value={progress}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                ) : (
                  <Typography>
                    {isDragActive
                      ? "Drop the files here..."
                      : "Drag 'n' drop files here, or click to select files"}
                  </Typography>
                )}
                {uploadError && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {uploadError}
                  </Alert>
                )}
              </Box>
            )}

            <Box
              sx={{
                ...(isModal && {
                  paddingBottom: "80px",
                }),
              }}
            >
              <div className="flex flex-wrap post-tab bg-white rounded-2xl p-3 mt-5 w-full mb-3">
                {/* COMMENT OUT SEARCH */}
                {/* <FormControl className="w-full mb-4" variant="outlined">
                <OutlinedInput
                  placeholder="Recent"
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      <IconButton onClick={handleClick} edge="end">
                        <img
                          src={darkMode ? listmenuwhite : listmenublack}
                          alt="List Menu"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <VaultSortingMenu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                />
              </FormControl> */}

                <TabContext value={selectedTab}>
                  <Box sx={{ overflowX: "auto" }}>
                    <Tabs
                      scrollButtons="auto"
                      variant="scrollable"
                      onChange={handleTabChange}
                      aria-label="vault tabs"
                    >
                      {vaultTabs?.map((tab) => (
                        <Tab
                          key={tab?.label}
                          icon={tab?.icon}
                          label={tab?.label}
                          value={tab?.value}
                        />
                      ))}
                    </Tabs>
                  </Box>

                  {/* TabPanels */}
                  <TabPanel
                    value="0"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                  <TabPanel
                    value="1"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                  <TabPanel
                    value="3"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                  <TabPanel
                    value="4"
                    className="w-full"
                    sx={{ padding: "0 !important" }}
                  ></TabPanel>
                </TabContext>
                <MediaSearchResults
                  items={transformedMedia}
                  onSelectItem={handleMediaSelect}
                  isMultiDrag={isMultiDrag}
                />
                {/* <MediaSearchResults items={items} onSelectItem={handleSelectItem} /> */}
                {isModal && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: 2,
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: darkMode
                        ? getColor("darkSecondary")
                        : getColor("white"),
                      borderTop: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                      zIndex: 1,
                      marginBottom: 0,
                    }}
                  >
                    <Typography variant="body1">
                      {selectedMedia.length} Selected
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        width: 150,
                        height: 47,
                        minHeight: "44px !important",
                        ml: 2,
                      }}
                      size="small"
                      onClick={handleAddSelected}
                    >
                      Add
                    </Button>
                  </Box>
                )}
              </div>
            </Box>
          </div>
        </Grid>
        <Grid item sm={12} md={4}>
          <Box sx={{ ml: 2 }}>
            <VaultSearchHeader />
            <Box className="post-tab bg-white rounded-2xl w-full mt-5">
              <FormControl
                className="w-full mb-4"
                variant="outlined"
                sx={{ p: 1.5, pb: 0 }}
              >
                <OutlinedInput
                  placeholder="Name"
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      <IconButton onClick={handleClick} edge="end">
                        <img
                          src={darkMode ? listmenuwhite : listmenublack}
                          alt="List Menu"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* <VaultSortingMenu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                /> */}
                <Button
                  variant="contained"
                  sx={{ my: 2 }}
                  onClick={() =>
                    dispatch(
                      openModal({
                        title: "Create Vault Folder",
                        content: <ModalCreateFolder />,
                        hasCloseBtn: true,
                      })
                    )
                  }
                >
                  Create Vault Folder
                </Button>
              </FormControl>
              <VaultList
                items={folders.map((f) => ({
                  ...f,
                  isSelected: f.id === selectedFolderId,
                }))}
                onFolderSelect={handleFolderSelect}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DndContext>
  );
}
