import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "../../../slices/common/toast";
import { addVaultFolder } from "../../../slices/creator/vault";
import vaultService from "../../../services/web/vault/vault.service";

export const getVaultMediaAction = createAsyncThunk(
  "vault/getVaultMedia",
  async (payload, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await vaultService.getVaultMedia(payload);
      if (response.status === 1) {
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const createVaultFolderAction = createAsyncThunk(
  "vault/createFolder",
  async (payload, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await vaultService.createFolder(payload);
      if (response.status === 1) {
        dispatch(openToast({ type: "success", message: "Folder created successfully" }));
        dispatch(addVaultFolder(response.response));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);