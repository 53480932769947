import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhotoIcon from "../../../../../assets/SVG/VaultTabs/PhotoIcon";
import VideoIcon from "../../../../../assets/SVG/VaultTabs/VideoIcon";
import AudioIcon from "../../../../../assets/SVG/VaultTabs/AudioIcon";
import ScreenMirroringIcon from "../../../../../assets/images/screenmirroring.svg";
import AudioPlayer from "../../../../molecules/web/common/media/AudioPlayer";
import VideoPlayer from "../../../../molecules/web/common/media/VideoPlayer";
import UserProfileImage from "../../../../molecules/web/home/Postheader/UserProfileImage";

const BookmarkMediaCard = ({ media }) => {
  const navigate = useNavigate();

  const getMediaIcon = () => {
    switch (media.mediaType) {
      case "image":
        return <PhotoIcon />;
      case "video":
        return <VideoIcon />;
      case "audio":
        return <AudioIcon />;
      default:
        return <img src={ScreenMirroringIcon} alt="Other" />;
    }
  };

  const handleUserProfileImageClick = () => {
    navigate(`/profile/${media.creator.username}`)
  };

  const handlePostRedirect = () => {
    navigate(`/${media.creator.username}/status/${media.postId}`);
  };

  const renderMedia = () => {
    if (media.isLocked) {
      return (
        <Box
          className="bg-primary-10"
          sx={{
            position: "relative",
            paddingTop: "100%",
            borderRadius: 1,
            overflow: "hidden"
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {getMediaIcon()}
          </Box>
        </Box>
      );
    }

    switch (media.mediaType) {
      case "image":
        return (
          <Box
            className="bg-primary-10"
            sx={{
              position: "relative",
              paddingTop: "100%",
              borderRadius: 1,
              overflow: "hidden",
              cursor: "pointer"
            }}
            onClick={handlePostRedirect}
          >
            <img
              src={`${process.env.REACT_APP_AWS_URL}${media.fileURL}`}
              alt={`Media by ${media.creator.username}`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
            />
          </Box>
        );
      case "video":
        return (
          <Box sx={{ borderRadius: 1, overflow: "hidden" }}>
            <VideoPlayer src={`${process.env.REACT_APP_AWS_URL}${media.fileURL}`} />
          </Box>
        );
      case "audio":
        return <AudioPlayer src={`${process.env.REACT_APP_AWS_URL}${media.fileURL}`} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 2,
        border: "1px solid #E0E0E0",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100%",
      }}
    >
      <UserProfileImage
        src={`${process.env.REACT_APP_AWS_URL}${media.creator.profileImage}`}
        name={media.creator.name}
        isVerified={media.creator.isVerified}
        size="small"
        onClick={handleUserProfileImageClick}
      />
      {renderMedia()}
      
    </Box>
  );
};

export default BookmarkMediaCard;
