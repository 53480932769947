import { FormControlLabel, Switch } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PushNotification from "./../../../../../../assets/images/push-notification.svg";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsPreferencesAction, updateNotificationsAction } from "../../../../../../store/middlewares/web/profile/myProfile/notifications";

const ModalPushNotifications = () => {
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.notifications);
  const getNotification = useCallback(() => {
    dispatch(getNotificationsPreferencesAction());
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  useEffect(() => {
    // set inital checked
    setChecked(items?.enabledPushNotification ? items.enabledPushNotification : false);
  }, [items?.enabledPushNotification]);

  const handleChange = () => {
    //Handle Change And Update Choice
    let reqObj = { UserData: { enabledPushNotification: !checked } };
    setChecked(!checked);
    dispatch(updateNotificationsAction(reqObj));
  };

  return (
    //title - PUSH NOTIFICATIONS
    <div className="gray-border border-radius-10 p-3">
      <p className="font-20 font-medium text-darkblue mb-3">Primary Options</p>
      <div className="lightgray-400 p-3 flex justify-between border-radius-10 gap-3">
        <div className="flex items-start gap-4 w-full">
          <img src={PushNotification} alt="PushNotification" />
          <div>
            <p className="font-18 font-medium text-darkblue ">
              Push Notifications
            </p>
            <p>
              Get push notifications to find out what’s going on when you’re not
              on YouOnly. You can turn them off anytime.
            </p>
          </div>
        </div>
        <FormControlLabel
          className="black-border"
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              name="checkedA"
              color="primary"
            />
          }
        />
      </div>
    </div>
  );
};

export default ModalPushNotifications;
