import { useEffect, useState } from "react";
import {
  requestNotificationPermission,
  onMessageListener,
} from "../config/firebase";
import { useDispatch } from "react-redux";
import { usePermission } from "@custom-react-hooks/use-permission";

export const useNotification = () => {
  const { state: permissionBrowserStatus } = usePermission("notifications");

  const [permission, setPermission] = useState(permissionBrowserStatus);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const initializeNotifications = async () => {
      try {
        await requestNotificationPermission();
        setPermission(permissionBrowserStatus);
        onMessageListener(dispatch).catch((err) => {
          console.error("Failed to receive message:", err);
          setError(err);
        });
      } catch (err) {
        console.error("Failed to initialize notifications:", err);
        setError(err);
      }
    };

    initializeNotifications();

    const tokenRefreshInterval = setInterval(initializeNotifications, 3600000);

    const handleFocus = () => {
      setPermission(permissionBrowserStatus);
      initializeNotifications();
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  return { permission, error };
};
