import { createSlice } from "@reduxjs/toolkit";
import { getMessagesAction, getMessageThreadAction, sendMessageAction } from "../../../middlewares/web/messages/messages";

const initialState = {
  messages: [],
  currentThread: null,
  loading: false,
  error: null,
  sendingMessage: false,
  sendError: null
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.messages = [];
      state.currentThread = null;
      state.error = null;
    },
    clearCurrentThread: (state) => {
      state.currentThread = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get Messages
      .addCase(getMessagesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMessagesAction.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
      })
      .addCase(getMessagesAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Get Message Thread
      .addCase(getMessageThreadAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMessageThreadAction.fulfilled, (state, action) => {
        state.loading = false;
        state.currentThread = action.payload;
      })
      .addCase(getMessageThreadAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Send Message
      .addCase(sendMessageAction.pending, (state) => {
        state.sendingMessage = true;
        state.sendError = null;
      })
      .addCase(sendMessageAction.fulfilled, (state, action) => {
        state.sendingMessage = false;
        if (state.currentThread) {
          state.currentThread.messages.push(action.payload);
        }
      })
      .addCase(sendMessageAction.rejected, (state, action) => {
        state.sendingMessage = false;
        state.sendError = action.error.message;
      });
  }
});

export const { clearMessages, clearCurrentThread } = messagesSlice.actions;
export default messagesSlice.reducer; 